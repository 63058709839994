import React from "react";

import type { UniquePageProps } from "../index";
import type { BusinessExpansionPageModel } from "@admin/domain/uniquePages/models/BusinessExpansionPageModel";
import MultiLanguages from "@admin/components/MultiLanguage";
import ImageInput from "@admin/components/ImageInput";

export default function BusinessExpansionPageNewDistributorRegister({
    page,
}: UniquePageProps<BusinessExpansionPageModel>) {
    return (
        <div className="mb-10">
            <h4 className="mb-5">Блок “Регистрация нового дистрибьютора</h4>

            <MultiLanguages
                languageElement={(language) => (
                    <>
                        <div className="mb-5">
                            <label className="form-label">Заголовок</label>
                            <input
                                className="form-control"
                                name={`content[new_distributor_block__title][${language}]`}
                                defaultValue={
                                    page.contentObject
                                        .new_distributor_block__title?.[
                                        language
                                    ] || ``
                                }
                            />
                        </div>

                        <div className="mb-5">
                            <label className="form-label">Описание</label>
                            <textarea
                                className="form-control"
                                name={`content[new_distributor_block__description][${language}]`}
                                defaultValue={
                                    page.contentObject
                                        .new_distributor_block__description?.[
                                        language
                                    ] || ``
                                }
                            />
                        </div>
                    </>
                )}
            />

            <div className="mb-5">
                <label className="form-label">
                    Ссылка на страницу регистрации нового дистрибьютора
                </label>
                <input
                    className="form-control"
                    name={`content[new_distributor_block__url]`}
                    defaultValue={page.contentObject.new_distributor_block__url}
                />
            </div>

            <div className="row">
                <div className="mb-5">
                    <ImageInput
                        label="Изображение"
                        name={`content[new_distributor_block__banner_file][file_id]`}
                        defaultValue={
                            page.contentObject
                                ?.new_distributor_block__banner_file
                        }
                        width="400px"
                        height="400px"
                    />
                </div>
            </div>
        </div>
    );
}

import React, { useState } from "react";
import { useLanguageContext } from "@admin/components/LanguagesContext";
import MultiLanguages from "@admin/components/MultiLanguage";
import {
    MainPageModel,
    PromotionModel,
} from "@admin/domain/uniquePages/models/MainPageModel";
import { type UniquePageProps } from "../index";
import ProductsSearch from "@admin/components/ProductsSearch";

type Banner = Partial<PromotionModel>;

export default function MainPagePromotions({
    page,
}: UniquePageProps<MainPageModel>) {
    const currentLanguage = useLanguageContext().currentLanguage;
    const [promotions, setPromotions] = useState<Banner[]>(
        page.contentObject.promotions || [],
    );

    const products = {};
    page.payload.products?.forEach((product) => {
        products[product.id] = product.texts.find(
            (item) => item.language === currentLanguage,
        )?.title;
    });

    return (
        <div className="mb-10">
            <h4 className="mb-5">Блок с акционными товарами</h4>
            <div className="row row-cols-2">
                {promotions.map((promotion, index) => (
                    <div key={promotion.product_id} className="col mb-5">
                        <ProductsSearch
                            label="Продукт"
                            inputName={`content[promotions][${index}][product_id]`}
                            productId={promotion.product_id}
                            productTitle={
                                products[promotion.product_id as string] || ``
                            }
                        />
                        <MultiLanguages
                            languageElement={(language) => (
                                <>
                                    <div className="mb-5">
                                        <label className="form-label">
                                            Описание
                                        </label>
                                        <input
                                            className="form-control"
                                            name={`content[promotions][${index}][description][${language}]`}
                                            defaultValue={
                                                promotion.description?.[
                                                    language
                                                ] || ``
                                            }
                                        />
                                    </div>
                                    <div>
                                        <button
                                            type="button"
                                            className="btn text-danger"
                                            onClick={() =>
                                                setPromotions(
                                                    promotions.filter(
                                                        (item) =>
                                                            item.product_id !==
                                                            promotion.product_id,
                                                    ),
                                                )
                                            }
                                        >
                                            Удалить
                                        </button>
                                    </div>
                                </>
                            )}
                        />
                    </div>
                ))}
            </div>
            <div className="mb-5 d-flex">
                <div className="me-5">
                    <button
                        type="button"
                        className="btn btn-primary"
                        onClick={() =>
                            setPromotions([
                                ...promotions,
                                {
                                    product_id: undefined,
                                    description: undefined,
                                },
                            ])
                        }
                    >
                        Добавить товар
                    </button>
                </div>
            </div>
        </div>
    );
}

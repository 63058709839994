import React from "react";
import DataLoader from "@admin/components/DataLoader";
import { getLogs } from "@admin/domain/logs/api";
import Table from "@admin/pages/LogsList/Table";
import Pagination from "@admin/pages/LogsList/Pagination";
import { LogData } from "@admin/domain/logs/dto/LogItem";
import Filters from "@admin/pages/LogsList/Filters";

export default function LogsList() {
    return (
        <DataLoader
            loader={async (): Promise<LogData> => getLogs(location.search)}
        >
            <Filters />
            <Table />
            <Pagination />
        </DataLoader>
    );
}

import React from "react";

import { type StoryPartial } from "./Stories";
import { StoryType } from "@admin/domain/chapters/models/Chapter";
import Switch from "@admin/components/Switch";
import Input from "@admin/components/Input";
import MultiLanguages from "@admin/components/MultiLanguage";
import ProductsSearch from "@admin/components/ProductsSearch";
import ImageInput from "@admin/components/ImageInput";

type Props = {
    index: number;
    story: StoryPartial;
    remove: () => void;
};

export default function StoryCard({ index, story, remove }: Props) {
    let title, additionalFields;

    switch (story.type) {
        case StoryType.Banner:
            title = `История с баннером`;
            additionalFields = (
                <>
                    <Input
                        label="Ссылка"
                        name={`stories[${index}][url]`}
                        defaultValue={story.url || ``}
                    />

                    <MultiLanguages
                        languageElement={(language) => (
                            <>
                                <input
                                    type="hidden"
                                    name={`stories[${index}][texts][${language}][language]`}
                                    value={language}
                                />

                                <Input
                                    label="Заголовок"
                                    name={`stories[${index}][texts][${language}][title]`}
                                    defaultValue={
                                        story.texts?.[language]?.title || ``
                                    }
                                />

                                <Input
                                    label="Подзаголовок"
                                    name={`stories[${index}][texts][${language}][sub_title]`}
                                    defaultValue={
                                        story.texts?.[language]?.sub_title || ``
                                    }
                                />
                            </>
                        )}
                    />
                </>
            );
            break;

        case StoryType.Product:
            title = `История с продуктом`;
            additionalFields = (
                <ProductsSearch
                    label="Товар"
                    productId={story.product?.id}
                    productTitle={story.product?.title}
                    inputName={`stories[${index}][product_id]`}
                />
            );
            break;

        default:
            return null;
    }

    return (
        <div className="col col-6 col-xxl-4 d-flex mb-8">
            <div className="p-4 flex-grow-1 d-flex flex-column justify-content-between border border-secondary rounded">
                <div>
                    <h4 className="mb-8">{title}</h4>

                    <input
                        type="hidden"
                        name={`stories[${index}][id]`}
                        value={story.id}
                    />

                    <input
                        type="hidden"
                        name={`stories[${index}][order]`}
                        value={index}
                    />

                    <input
                        type="hidden"
                        name={`stories[${index}][type]`}
                        value={story.type}
                    />

                    <Switch
                        label="Скрыть / Показать"
                        name={`stories[${index}][is_public]`}
                        defaultChecked={story.is_public}
                    />

                    <Switch
                        label="Светлое изображение"
                        name={`stories[${index}][light_background]`}
                        defaultChecked={story.light_background}
                    />

                    <ImageInput
                        label=""
                        name={`stories[${index}][file_id]`}
                        defaultValue={story.file}
                        width="150px"
                        thumbnailHeight="150px"
                        thumbnailWidth="150px"
                    />

                    <Input
                        label="Время проигрывания в секундах"
                        type="number"
                        pattern="[0-9]*"
                        name={`stories[${index}][playback_duration]`}
                        defaultValue={story.playback_duration || ``}
                    />

                    {additionalFields}
                </div>

                <div className="mt-8">
                    <a className="card-link text-danger" onClick={remove}>
                        Удалить историю
                    </a>
                </div>
            </div>
        </div>
    );
}

import React, { useState } from "react";
import { v4 as uuid } from "uuid";

import { TextListModel } from "@admin/domain/articles/models/ArticleBlockValueModel";
import { ArticleContentBlockModel } from "@admin/domain/articles/models/ArticleContentBlockModel";
import { ArticleBlockType } from "@admin/domain/articles/enums/ArticleBlockType";
import MultiLanguages from "@admin/components/MultiLanguage";

type List = Partial<TextListModel>;

type Props = {
    block: ArticleContentBlockModel & {
        type: ArticleBlockType.OrderedList | ArticleBlockType.UnorderedList;
    };
    index: number;
};

export default function ItemsListBlock({ block: { id, value }, index }: Props) {
    const initList = (value?.list || []).map((value) => ({
        ...value,
        id: uuid(),
    }));

    const [list, setEl] = useState<List[]>(initList);

    return (
        <>
            <input
                type="hidden"
                name={`blocks[${index}][value][id]`}
                value={id}
            />

            <MultiLanguages
                languageElement={(language) => (
                    <>
                        <div className="mb-5">
                            <label className="form-label">Заголовок</label>
                            <textarea
                                className="form-control"
                                name={`blocks[${index}][value][title][${language}]`}
                                defaultValue={value?.title?.[language] || ``}
                            />
                        </div>

                        <div className="mb-5">
                            <label className="form-label">Текст</label>
                            <textarea
                                className="form-control"
                                name={`blocks[${index}][value][text][${language}]`}
                                defaultValue={value?.text?.[language] || ``}
                            />
                        </div>

                        <div className="mb-5">
                            <label className="form-label">
                                Текст после списка
                            </label>
                            <textarea
                                className="form-control"
                                name={`blocks[${index}][value][additional_text][${language}]`}
                                defaultValue={
                                    value?.additional_text?.[language] || ``
                                }
                            />
                        </div>
                    </>
                )}
            />

            <div className="row">
                {list.map((ol, itemIndex) => (
                    <div key={ol.id} className="col col-4 mb-5">
                        <MultiLanguages
                            languageElement={(language) => (
                                <div className="mb-5">
                                    <label className="form-label">
                                        Элемент списка
                                    </label>
                                    <input
                                        className="form-control"
                                        name={`blocks[${index}][value][list][${itemIndex}][title][${language}]`}
                                        defaultValue={
                                            ol.title?.[language] || ``
                                        }
                                    />
                                </div>
                            )}
                        />

                        <div>
                            <a
                                className="card-link text-danger"
                                onClick={() => {
                                    setEl((prev) =>
                                        prev.filter(
                                            (value) => value.id !== ol.id,
                                        ),
                                    );
                                }}
                            >
                                Удалить
                            </a>
                        </div>
                    </div>
                ))}
            </div>

            <div className="mb-5 d-flex">
                <div className="me-5">
                    <button
                        type="button"
                        className="btn btn-success"
                        onClick={() =>
                            setEl((elState) => [
                                ...elState,
                                {
                                    id: uuid(),
                                    title: undefined,
                                },
                            ])
                        }
                    >
                        Добавить элемент списка
                    </button>
                </div>
            </div>
        </>
    );
}

import React from "react";
import get from "lodash/get";

import { useOrderContext } from "@admin/pages/OrderEditor/OrderContext";
import { EditOrderData } from "@admin/domain/orders/dto/EditOrderData";
import BaseModal from "@admin/components/Modal";

type Props = {
    orderChanges: Map<string, [string, string]>;
    closeModal: () => void;
    actualizeOrder: () => void;
    overwriteOrder: () => void;
    actualOrder: EditOrderData;
};

export default function OrderChangesFromDistributorModal({
    orderChanges,
    closeModal,
    actualizeOrder,
    overwriteOrder,
    actualOrder,
}: Props) {
    const { order } = useOrderContext();

    return (
        <BaseModal
            closeModal={closeModal}
            title="Заказ был изменен Дистрибьютором"
            body={
                <>
                    <p>
                        Пока Вы работали с заказом, его изменил Дистрибьютор.
                        Если Вы нажмете “Не сохранять”, Ваши изменения будут
                        отменены, если “Сохранить мои изменения” - перезапишите
                        изменения Дистрибьютора
                    </p>
                    <table className="table table-striped gy-7 gs-7 overflow-auto w-100 mb-5">
                        <thead>
                            <tr>
                                <td scope="col">Имя поля</td>
                                <td scope="col">Предыдущее значение</td>
                                <td scope="col">Значение Дистрибьютора</td>
                            </tr>
                        </thead>
                        <tbody>
                            {Array.from(
                                orderChanges,
                                ([fieldPath, [fieldName, fieldValuePath]]) => (
                                    <tr key={fieldPath}>
                                        <td>{fieldName}</td>
                                        <td>{get(order, fieldValuePath)}</td>
                                        <td>
                                            {get(actualOrder, fieldValuePath)}
                                        </td>
                                    </tr>
                                ),
                            )}
                        </tbody>
                    </table>
                </>
            }
            footer={
                <>
                    <button className="btn btn-danger" onClick={closeModal}>
                        Отменить
                    </button>

                    <button className="btn btn-info" onClick={actualizeOrder}>
                        Не сохранять
                    </button>

                    <button
                        className="btn btn-primary"
                        onClick={overwriteOrder}
                    >
                        Сохранить мои изменения
                    </button>
                </>
            }
        />
    );
}

import React, { useState } from "react";
import { v4 as uuid } from "uuid";

import { ProgramRuleModel } from "@admin/domain/articles/models/ArticleBlockValueModel";
import { ArticleContentBlockModel } from "@admin/domain/articles/models/ArticleContentBlockModel";
import { ArticleBlockType } from "@admin/domain/articles/enums/ArticleBlockType";
import MultiLanguages from "@admin/components/MultiLanguage";
import ProductsSearch from "@admin/components/ProductsSearch";
import ProgramRulesRecommendations from "./ProgramRulesRecommendations";

type Products = Partial<ProgramRuleModel>;

type Props = {
    block: ArticleContentBlockModel & {
        type: ArticleBlockType.ProgramRules;
    };
    index: number;
};

export default function ProgramRulesBlock({ block: { value }, index }: Props) {
    const initList = (value?.program_rules || []).map((value: any) => ({
        ...value,
        id: uuid(),
    }));

    const [products, setProducts] = useState<Products[]>(initList);

    return (
        <>
            <MultiLanguages
                languageElement={(language) => (
                    <>
                        <div className="mb-5">
                            <label className="form-label">Заголовок</label>
                            <input
                                className="form-control"
                                name={`blocks[${index}][value][title][${language}]`}
                                defaultValue={value?.title?.[language] || ``}
                            />
                        </div>

                        <div className="mb-5">
                            <label className="form-label">Текст</label>
                            <input
                                className="form-control"
                                name={`blocks[${index}][value][text][${language}]`}
                                defaultValue={value?.text?.[language] || ``}
                            />
                        </div>

                        <div className="mb-5">
                            <label className="form-label">Условие курса</label>
                            <input
                                className="form-control"
                                name={`blocks[${index}][value][course_condition][${language}]`}
                                defaultValue={
                                    value?.course_condition?.[language] || ``
                                }
                            />
                        </div>
                    </>
                )}
            />

            <div className="rounded border p-10 my-5">
                <h3>Товары</h3>

                {products.map((product, productIndex: number) => (
                    <div
                        key={product.id || `product-${index}`}
                        className="col mb-5"
                    >
                        <ProductsSearch
                            inputName={`blocks[${index}][value][program_rules][${productIndex}][product_id]`}
                            productId={product?.product_id}
                            productTitle={product?.product_title || ``}
                        />

                        <MultiLanguages
                            languageElement={(language) => (
                                <div>
                                    <div className="mb-5">
                                        <label className="form-label">
                                            Заголовок
                                        </label>
                                        <input
                                            className="form-control"
                                            name={`blocks[${index}][value][program_rules][${productIndex}][title][${language}]`}
                                            defaultValue={
                                                product?.title?.[language] || ``
                                            }
                                        />
                                    </div>
                                </div>
                            )}
                        />

                        <ProgramRulesRecommendations
                            index={productIndex}
                            blockIndex={index}
                            recommendations={product.recommendations || []}
                        />

                        <div>
                            <a
                                className="card-link text-danger"
                                onClick={() => {
                                    setProducts((prev) =>
                                        prev.filter(
                                            (value) => value.id !== product.id,
                                        ),
                                    );
                                }}
                            >
                                Удалить
                            </a>
                        </div>
                    </div>
                ))}

                <div className="mb-5 d-flex">
                    <div className="me-5">
                        <button
                            type="button"
                            className="btn btn-primary"
                            onClick={() =>
                                setProducts((elState) => [
                                    ...elState,
                                    {
                                        id: uuid(),
                                        title: undefined,
                                    },
                                ])
                            }
                        >
                            Добавить товар
                        </button>
                    </div>
                </div>
            </div>
        </>
    );
}

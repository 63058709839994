import { EditOrderData } from "@admin/domain/orders/dto/EditOrderData";
import { OrderType } from "@admin/domain/orders/enums/OrderType";
import React from "react";

export default function OrderTypeInfo({ order }: { order: EditOrderData }) {
    return (
        <>
            <span className="fw-bolder">Тип заказа: </span>
            {(() => {
                switch (true) {
                    case order.is_parent:
                        return (
                            <>
                                Консолидированный заказ
                                <div>
                                    <span className="fw-bolder">
                                        Подзаказы:{` `}
                                    </span>
                                    {order.sub_orders.map((subOrder, index) => (
                                        <span key={subOrder.id}>
                                            <a
                                                href={`/admin/orders/${subOrder.id}`}
                                                target="_blank"
                                            >
                                                {subOrder.id}
                                            </a>
                                            <span>
                                                {index ===
                                                order.sub_orders.length - 1
                                                    ? ``
                                                    : `, `}
                                            </span>
                                        </span>
                                    ))}
                                </div>
                            </>
                        );
                    case order.is_child:
                        return (
                            <>
                                Подзаказ в рамках консолидированного заказа
                                <a
                                    href={`/admin/orders/${order.consolidated_order_id}`}
                                >
                                    {` `}
                                    {order.consolidated_order_id}
                                </a>
                            </>
                        );
                    case order.type === OrderType.Simple:
                        return <>Обычный заказ</>;
                }
            })()}
        </>
    );
}

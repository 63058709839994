import React from "react";
import type { EditOrderData } from "@admin/domain/orders/dto/EditOrderData";
import { Collapse } from "react-bootstrap";
import ReactJson from "react-json-view";

type Props = {
    order: EditOrderData;
};

export default function AnalyticalDb({ order }: Props) {
    const [analyticalDbDataIsCollapsed, setAnalyticalDbDataIsCollapsed] =
        React.useState<boolean>(false);

    return (
        <>
            <h4 className="mb-5">Синхронизация с АБ</h4>
            {order.analytical_db_data && (
                <div className="border rounded p-5 mb-3">
                    <div
                        className="cursor-pointer"
                        onClick={(): void =>
                            setAnalyticalDbDataIsCollapsed(
                                !analyticalDbDataIsCollapsed,
                            )
                        }
                    >
                        Заказ есть в Аналитической базе
                    </div>
                    <Collapse in={analyticalDbDataIsCollapsed}>
                        <div className="mb-5">
                            <p>
                                Данные заказа, переданные в Аналитическую базу
                            </p>
                            <ReactJson
                                src={order.analytical_db_data}
                                displayDataTypes={false}
                                displayObjectSize={false}
                                collapseStringsAfterLength={false}
                                enableClipboard={false}
                                name={false}
                            />
                        </div>
                    </Collapse>
                </div>
            )}
            {!order.analytical_db_data && (
                <p>Заказ отсутствует в Аналитической базе</p>
            )}
        </>
    );
}

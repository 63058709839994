import React from "react";
import DateTimeInput from "@admin/components/DateTimeInput";
import CheckBox from "@admin/components/CheckBox";
import Texts from "@admin/pages/UniquePageEditor/LiveBroadcasts/Texts";
import Speakers from "@admin/pages/UniquePageEditor/LiveBroadcasts/Speakers";
import FilesList from "@admin/components/FilesList";
import VideoInput from "@admin/components/VideoInput";
import { LiveBroadcastModel } from "@admin/domain/uniquePages/models/LiveBroadcastsPageModel";

type Props = {
    broadcast: LiveBroadcastModel;
    broadcastIndex: number;
    deleteBroadcast: (broadcastIndex: number) => void;
};

export default function BroadcastItem({
    broadcast,
    broadcastIndex,
    deleteBroadcast,
}: Props) {
    return (
        <div className="rounded border p-10 mb-5" key={broadcastIndex}>
            <DateTimeInput
                label="Время начала эфира"
                name={`content[broadcasts][${broadcastIndex}][starts_at]`}
                value={broadcast.starts_at || undefined}
            />

            <CheckBox
                label="Эфир закончен"
                name={`content[broadcasts][${broadcastIndex}][is_finished]`}
                defaultChecked={broadcast.is_finished}
            />

            <Texts
                broadcastIndex={broadcastIndex}
                title={broadcast.title}
                description={broadcast.description}
            />

            <Speakers
                speakers={broadcast.speakers}
                broadcastIndex={broadcastIndex}
            />

            <div className="mb-5">
                <h4 className="mb-5">Документы</h4>
                <FilesList
                    files={broadcast.documents || []}
                    fileInputFunction={(fileIndex: number) =>
                        `content[broadcasts][${broadcastIndex}][documents][${fileIndex}]`
                    }
                />
            </div>

            <div className="mb-5">
                <h4 className="mb-5">Видео</h4>
                <VideoInput
                    name={`content[broadcasts][${broadcastIndex}][video]`}
                    defaultValue={{
                        file: broadcast.video,
                        url: broadcast.video_link,
                    }}
                />
            </div>

            <button
                type="button"
                className="btn btn-danger"
                onClick={(): void => deleteBroadcast(broadcastIndex)}
            >
                Удалить эфир
            </button>
        </div>
    );
}

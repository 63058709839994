import React from "react";

import type { NewsTextModel } from "@admin/domain/news/models/NewsTextModel";
import type { Language } from "@admin/domain/languages/Languages";
import type { AdminModel } from "@admin/domain/admins/models/AdminModel";
import { saveNewsArticle } from "@admin/domain/news/api";
import Editor from "@admin/components/Editor";
import ContentBlocks from "@admin/components/Content/ContentBlocks";
import PublishedAtInput from "@admin/components/PublishedAtInput";
import Input from "@admin/components/Input";
import MultiLanguages from "@admin/components/MultiLanguage";
import TextArea from "@admin/components/TextArea";
import CheckBox from "@admin/components/CheckBox";
import PageSeoParamsEditor from "@admin/components/PageSeoParamsEditor";
import AdminsSearch from "@admin/components/AdminsSearch";
import ImageInput from "@admin/components/ImageInput";

const LIST_URL = `/admin/news` as const;

const NewsArticleEditor: React.FC = () => {
    const article = window.CMS.news || null;
    const admins = window.CMS.admins || [];
    const firstAdminId = Object.keys(admins)[0] as unknown as number;
    const admin = article?.author
        ? article?.author
        : ({
              id: firstAdminId,
              email: ``,
              name: admins[firstAdminId],
          } as AdminModel);
    const texts = article?.texts || [];
    const textMap = new Map(
        texts.map((text: NewsTextModel): [Language, NewsTextModel] => [
            text.language,
            text,
        ]),
    );

    const execute = async (body: FormData): Promise<void> => {
        await saveNewsArticle(article, body);
    };

    return (
        <Editor backUrl={LIST_URL} onSubmit={execute}>
            <PublishedAtInput defaultValue={article?.published_at} />

            <Input
                label="Символьный код для URL (автоматически генерируется из заголовка страницы)"
                name="path"
                defaultValue={article?.path}
                disabled={true}
            />

            <MultiLanguages
                languageElement={(language: Language): React.JSX.Element => (
                    <>
                        <input
                            type="hidden"
                            name={`texts[${language}][language]`}
                            defaultValue={language}
                        />

                        <Input
                            label="Заголовок страницы"
                            name={`texts[${language}][title]`}
                            defaultValue={textMap.get(language)?.title}
                        />

                        <TextArea
                            label="Аннотация"
                            name={`texts[${language}][annotation]`}
                            defaultValue={textMap.get(language)?.annotation}
                        />
                    </>
                )}
            />

            <ImageInput
                label="Изображение"
                name="preview_image_id"
                defaultValue={article?.preview_image}
                height={300}
            />

            <AdminsSearch
                label="Автор"
                inputName={`author_id`}
                adminId={admin?.id}
                adminName={admin?.name}
            />

            <CheckBox
                label="Скрыть / Показать"
                name="is_visible"
                defaultChecked={!article || article.is_visible}
            />

            <CheckBox
                label="Показывать для МП"
                name="is_visible_for_mobile_app"
                defaultChecked={!article || article.is_visible_for_mobile_app}
            />

            <CheckBox
                label="Показывать для сайта"
                name="is_visible_for_site"
                defaultChecked={!article || article.is_visible_for_site}
            />

            <PageSeoParamsEditor />

            <ContentBlocks blocks={article?.blocks} />
        </Editor>
    );
};

export default NewsArticleEditor;

import React, { type ChangeEvent, createRef, useState } from "react";
import Modal from "react-bootstrap/Modal";
import Spinner from "react-bootstrap/Spinner";
import { upload } from "@admin/domain/products/api";
import { useProductMediaFilesDispatch } from "./ProductMediaFilesContext";

export default function ProductMediaFilesButtons() {
    const dispatch = useProductMediaFilesDispatch();

    const imageUpload: React.RefObject<HTMLInputElement> = createRef();
    const [uploadFiles, setUploadFiles] = useState<{
        file?: File;
        preview?: File | null;
    }>({});

    const [modalShow, setModalShow] = useState(false);
    const [loadStatus, setLoadStatus] = useState(false);

    async function onAddImage(event: ChangeEvent<HTMLInputElement>) {
        const mediaFile = await upload(event.target.files?.item(0) as File);

        dispatch({ type: `add`, productMediaFile: mediaFile });
    }

    async function submitModal() {
        if (!uploadFiles.file || !uploadFiles.preview) {
            return;
        }

        setLoadStatus(true);

        const mediaFile = await upload(uploadFiles.file, uploadFiles.preview);

        dispatch({ type: `add`, productMediaFile: mediaFile });

        setLoadStatus(false);
        setModalShow(false);
    }

    return (
        <div className="mb-5">
            <div className="mb-5 d-flex">
                <div className="me-5">
                    <input
                        className="d-none"
                        type="file"
                        accept="image/*"
                        ref={imageUpload}
                        onChange={onAddImage}
                    />
                    <button
                        type="button"
                        className="btn btn-primary"
                        onClick={() => imageUpload.current?.click()}
                    >
                        Добавить изображение
                    </button>
                </div>
                <button
                    className="btn btn-primary"
                    onClick={() => setModalShow(true)}
                >
                    Добавить видео
                </button>
            </div>
            <Modal show={modalShow}>
                <Modal.Header>
                    <Modal.Title>Загрузка видео</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div>
                        <label className="form-label">Видео файл</label>
                        <input
                            type="file"
                            required
                            className="form-control"
                            name="file"
                            accept="video/*"
                            onChange={(event) =>
                                setUploadFiles((prevState) => ({
                                    ...prevState,
                                    file: event.target.files?.item(0) as File,
                                }))
                            }
                        />
                    </div>
                    <div className="mt-5">
                        <label className="form-label">
                            Превью для видео файла
                        </label>
                        <input
                            type="file"
                            required
                            className="form-control"
                            name="file"
                            accept="image/*"
                            onChange={(event) =>
                                setUploadFiles((prevState) => ({
                                    ...prevState,
                                    preview: event.target.files?.item(
                                        0,
                                    ) as File,
                                }))
                            }
                        />
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button
                        className="btn btn-secondary"
                        onClick={() => setModalShow(false)}
                    >
                        Закрыть
                    </button>
                    <button
                        className="btn btn-primary"
                        onClick={() => submitModal()}
                        disabled={loadStatus}
                    >
                        {!loadStatus ? (
                            <span>Загрузить</span>
                        ) : (
                            <span>
                                <Spinner
                                    className="me-3"
                                    as="span"
                                    animation="grow"
                                    size="sm"
                                    role="status"
                                    aria-hidden="true"
                                />
                                Загрузка...
                            </span>
                        )}
                    </button>
                </Modal.Footer>
            </Modal>
        </div>
    );
}

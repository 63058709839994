import React from "react";

import type { BlockProps } from "@admin/components/Content/ContentBlocks";
import type { SharingBlockValueModel } from "@admin/domain/content/models/ContentBlockValueModel";

const checkBoxes = [
    `vkontakte`,
    `odnoklassniki`,
    `telegram`,
    `whats_app`,
    `viber`,
] as const;

const defaultValue: SharingBlockValueModel = {
    social_networks: {
        vkontakte: true,
        odnoklassniki: true,
        telegram: true,
        whats_app: true,
        viber: true,
    },
} as const;

type Props = BlockProps<SharingBlockValueModel>;

export default function SharingBlock({
    index,
    value = structuredClone(defaultValue),
}: Props) {
    return checkBoxes.map((socialNetwork) => (
        <div key={`checkbox-${socialNetwork}`} className="mb-5">
            <div className="form-check form-check-custom form-check-solid">
                <input
                    type="hidden"
                    name={`blocks[${index}][value][social_networks][${socialNetwork}]`}
                    defaultValue="0"
                />
                <input
                    id={`checkbox-${socialNetwork}`}
                    className="form-check-input"
                    type="checkbox"
                    name={`blocks[${index}][value][social_networks][${socialNetwork}]`}
                    defaultChecked={!!value.social_networks[socialNetwork]}
                    defaultValue="1"
                />
                <label
                    className="form-check-label"
                    htmlFor={`checkbox-${socialNetwork}`}
                >
                    {socialNetwork}
                </label>
            </div>
        </div>
    ));
}

import React, { createRef, useEffect, useState } from "react";
import MultiLanguages from "@admin/components/MultiLanguage";
import { type UniquePageProps } from "../index";
import { BusinessPageModel } from "@admin/domain/uniquePages/models/BusinessPageModel";
import { FileModel } from "@admin/domain/files/model/FileModel";
import { upload } from "@admin/domain/products/api";
import { makePath } from "@admin/utils/makePath";

export default function BusinessPageCalculator({
    page,
}: UniquePageProps<BusinessPageModel>) {
    const [document, setDocument] = useState<FileModel | null>(null);
    const fileUpload = createRef<HTMLInputElement>();
    useEffect(() => {
        if (page?.contentObject?.calculator__marketing_plan__file) {
            setDocument(page?.contentObject?.calculator__marketing_plan__file);
        }
    }, [page?.contentObject?.calculator__marketing_plan__file]);

    async function addFile(event: React.ChangeEvent<HTMLInputElement>) {
        await upload(event.target.files?.item(0) as File).then((document) =>
            setDocument(document),
        );
    }
    return (
        <div className="mb-10">
            <h4 className="mb-5">Блок с калькулятором дохода</h4>
            <div>
                <MultiLanguages
                    languageElement={(language) => (
                        <div>
                            <div className="mb-5">
                                <label className="form-label">Заголовок</label>
                                <input
                                    className="form-control"
                                    name={`content[calculator__title][${language}]`}
                                    defaultValue={
                                        page.contentObject.calculator__title?.[
                                            language
                                        ] || ``
                                    }
                                />
                            </div>
                            <div className="mb-5">
                                <label className="form-label">Описание</label>
                                <textarea
                                    className="form-control"
                                    name={`content[calculator__description][${language}]`}
                                    defaultValue={
                                        page.contentObject
                                            .calculator__description?.[
                                            language
                                        ] || ``
                                    }
                                />
                            </div>
                            <h3>Входные параметры</h3>
                            <div className="mb-5">
                                <label className="form-label">
                                    Заголовок (Ваши продажи в месяц)
                                </label>
                                <input
                                    className="form-control"
                                    name={`content[calculator__your_sales__title][${language}]`}
                                    defaultValue={
                                        page.contentObject
                                            .calculator__your_sales__title?.[
                                            language
                                        ] || ``
                                    }
                                />
                            </div>
                            <div className="mb-5">
                                <label className="form-label">
                                    Сумма ваших личных покупок
                                </label>
                                <input
                                    className="form-control"
                                    name={`content[calculator__your_sales__calculate_labels__sum][${language}]`}
                                    defaultValue={
                                        page.contentObject
                                            .calculator__your_sales__calculate_labels__sum?.[
                                            language
                                        ] || ``
                                    }
                                />
                            </div>
                            <div className="mb-5">
                                <label className="form-label">
                                    Количество ваших клиентов
                                </label>
                                <input
                                    className="form-control"
                                    name={`content[calculator__your_sales__calculate_labels__clients_count][${language}]`}
                                    defaultValue={
                                        page.contentObject
                                            .calculator__your_sales__calculate_labels__clients_count?.[
                                            language
                                        ] || ``
                                    }
                                />
                            </div>
                            <div className="mb-5">
                                <label className="form-label">
                                    Сумма покупок одного клиента
                                </label>
                                <input
                                    className="form-control"
                                    name={`content[calculator__your_sales__calculate_labels__client_purchase_amount][${language}]`}
                                    defaultValue={
                                        page.contentObject
                                            .calculator__your_sales__calculate_labels__client_purchase_amount?.[
                                            language
                                        ] || ``
                                    }
                                />
                            </div>
                            <div className="mb-5">
                                <label className="form-label">
                                    Заголовок (Продажи команды в месяц)
                                </label>
                                <input
                                    className="form-control"
                                    name={`content[calculator__team_sales__title][${language}]`}
                                    defaultValue={
                                        page.contentObject
                                            .calculator__team_sales__title?.[
                                            language
                                        ] || ``
                                    }
                                />
                            </div>
                            <div className="mb-5">
                                <label className="form-label">
                                    Количество Партнёров в Команде
                                </label>
                                <input
                                    className="form-control"
                                    name={`content[calculator__team_sales__calculate_partners_count][${language}]`}
                                    defaultValue={
                                        page.contentObject
                                            .calculator__team_sales__calculate_partners_count?.[
                                            language
                                        ] || ``
                                    }
                                />
                            </div>
                            <div className="mb-5">
                                <label className="form-label">
                                    Сумма продаж у одного Партнёра
                                </label>
                                <input
                                    className="form-control"
                                    name={`content[calculator__team_sales__calculate_labels__partner_purchase_amount][${language}]`}
                                    defaultValue={
                                        page.contentObject
                                            .calculator__team_sales__calculate_labels__partner_purchase_amount?.[
                                            language
                                        ] || ``
                                    }
                                />
                            </div>
                            <h3>Выодные параметры</h3>
                            <div className="mb-5">
                                <label className="form-label">
                                    Заголовок (Личный объём в месяц)
                                </label>
                                <input
                                    className="form-control"
                                    name={`content[calculator__result_labels__result_per_month][${language}]`}
                                    defaultValue={
                                        page.contentObject
                                            .calculator__result_labels__result_per_month?.[
                                            language
                                        ] || ``
                                    }
                                />
                            </div>
                            <div className="mb-5">
                                <label className="form-label">
                                    Заголовок (Групповой объём в месяц)
                                </label>
                                <input
                                    className="form-control"
                                    name={`content[calculator__result_labels__team_result_per_month][${language}]`}
                                    defaultValue={
                                        page.contentObject
                                            .calculator__result_labels__team_result_per_month?.[
                                            language
                                        ] || ``
                                    }
                                />
                            </div>
                            <div className="mb-5">
                                <label className="form-label">
                                    Заголовок (Ежемесячный доход)
                                </label>
                                <input
                                    className="form-control"
                                    name={`content[calculator__result_labels__monthly_income][${language}]`}
                                    defaultValue={
                                        page.contentObject
                                            .calculator__result_labels__monthly_income?.[
                                            language
                                        ] || ``
                                    }
                                />
                            </div>
                            <div className="mb-5">
                                <label className="form-label">
                                    Заголовок (Квалификация)
                                </label>
                                <input
                                    className="form-control"
                                    name={`content[calculator__result_labels__qualification][${language}]`}
                                    defaultValue={
                                        page.contentObject
                                            .calculator__result_labels__qualification?.[
                                            language
                                        ] || ``
                                    }
                                />
                            </div>
                            <div className="mb-5">
                                <label className="form-label">
                                    Текст кнопки 1
                                </label>
                                <input
                                    className="form-control"
                                    name={`content[calculator__advantage__button_text][${language}]`}
                                    defaultValue={
                                        page.contentObject
                                            .calculator__advantage__button_text?.[
                                            language
                                        ] || ``
                                    }
                                />
                            </div>
                            <div className="mb-5">
                                <label className="form-label">
                                    Текст кнопки 2
                                </label>
                                <input
                                    className="form-control"
                                    name={`content[calculator__marketing_plan__button_text][${language}]`}
                                    defaultValue={
                                        page.contentObject
                                            .calculator__marketing_plan__button_text?.[
                                            language
                                        ] || ``
                                    }
                                />
                            </div>
                        </div>
                    )}
                />
                <div className="mb-5">
                    <label className="form-label">Ссылка на видео</label>
                    <input
                        className="form-control"
                        name={`content[calculator__advantage__url]`}
                        defaultValue={
                            page.contentObject.calculator__advantage__url
                        }
                    />
                </div>
                <div className="row">
                    <h4 className="mb-5">Файл маркетинг-плана</h4>
                    <br />
                    {!document && (
                        <div className="mb-5">
                            <input
                                className="d-none"
                                type="file"
                                accept=".pdf"
                                ref={fileUpload}
                                onChange={addFile}
                            />
                            <button
                                type="button"
                                className="btn btn-primary"
                                onClick={() => fileUpload.current?.click()}
                            >
                                Загрузить файл
                            </button>
                        </div>
                    )}
                    {document && (
                        <div className="card border mb-4 position-relative">
                            <div className="card-body">
                                <div className="d-flex flex-column justify-content-center">
                                    <div>
                                        <span className="fw-bolder">
                                            Название файла:
                                        </span>
                                        {document?.name}
                                    </div>
                                    <div>
                                        <span className="fw-bolder">
                                            Формат файла:
                                        </span>
                                        {document?.mime}
                                    </div>
                                    <div>
                                        <span className="fw-bolder">
                                            Дата загрузки:
                                        </span>
                                        {document?.created_at}
                                    </div>
                                    <a
                                        href={
                                            page.contentObject
                                                .calculator__marketing_plan__file
                                                ? makePath(document.path)
                                                : ``
                                        }
                                        target="_blank"
                                        className="fw-bolder"
                                    >
                                        Скачать
                                    </a>
                                    <input
                                        type="hidden"
                                        name={`content[calculator__marketing_plan__file][file_id]`}
                                        defaultValue={document.id}
                                    />
                                </div>
                            </div>
                            <i
                                className="bi bi-x-circle position-absolute text-danger end-0 mt-5 me-5 cursor-pointer"
                                onClick={() => {
                                    setDocument(null);
                                }}
                            />
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
}

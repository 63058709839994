import React from "react";
import Input from "../../../../components/Input";
import { LocalityModel } from "@admin/domain/localities/models/LocalityModel";
import { useOrderContext } from "@admin/pages/OrderEditor/OrderContext";
import { PaymentMethodModel } from "@admin/domain/payments/models/PaymentMethodModel";
import { DeliveryMethodModel } from "@admin/domain/deliveryMethods/models/DeliveryMethodModel";
import {
    EditOrderData,
    EditOrderShippingInfoData,
} from "@admin/domain/orders/dto/EditOrderData";
import BaseModal from "@admin/components/Modal";
import LocalitiesSearch from "@admin/components/LocalitiesSearch";
import { getDefaultCountry } from "@admin/domain/countries/getDefaultCountry";
import { CountryModel } from "@admin/domain/countries/models/CountryModel";
import DeliveryPoints from "@admin/pages/OrderEditor/GeneralInfo/DeliveryAndPayment/DeliveryPoints";
import { DeliveryPointModel } from "@admin/domain/deliveryPoints/models/DeliveryPointModel";

type Props = {
    closeModal: () => void;
};

export default function DeliveryAndPaymentEditorModal({ closeModal }: Props) {
    const { order, paymentMethods, setOrder, countries, deliveryMethods } =
        useOrderContext();

    const country = getDefaultCountry(countries);
    const defaultDeliveryMethod = deliveryMethods.values().next()
        .value as DeliveryMethodModel;

    const [shippingInfo, setShippingInfo] =
        React.useState<EditOrderShippingInfoData>(
            order.shipping_info || {
                country_id: country.id,
                country_name: country.name,
                delivery_method_id: defaultDeliveryMethod.id,
                delivery_method_title: defaultDeliveryMethod.title,
                delivery_point_address: ``,
                delivery_point_id: null,
                edited_by_accounting_at: ``,
                edited_by_distributor_at: ``,
                email: ``,
                family_name: ``,
                locality_id: 0,
                locality_name: ``,
                name: ``,
                patronymic: ``,
                phone: ``,
                postcode: ``,
                street: ``,
                house: ``,
                flat: ``,
                updated_at: ``,
            },
        );

    const [paymentMethod, setPaymentMethod] =
        React.useState<PaymentMethodModel>(
            order.payment_method || paymentMethods.values().next().value,
        );

    const setShippingInfoField = (
        event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>,
    ): void => {
        setShippingInfo(
            (prev: EditOrderShippingInfoData): EditOrderShippingInfoData => ({
                ...prev,
                [event.target.name]: event.target.value,
            }),
        );
    };

    const deliveryMethod = deliveryMethods.get(
        shippingInfo.delivery_method_id,
    ) as DeliveryMethodModel;

    const submit = (): void => {
        if (!shippingInfo.locality_id) {
            alert(`Выберите город`);

            return;
        }

        if (
            deliveryMethod.has_delivery_points &&
            !shippingInfo.delivery_point_id
        ) {
            alert(`Выберите пункт выдачи`);

            return;
        }

        setOrder(
            (prev: EditOrderData): EditOrderData => ({
                ...prev,
                shipping_info: shippingInfo,
                payment_method: paymentMethod,
            }),
        );
        closeModal();
    };

    const setLocality = (locality: LocalityModel): void =>
        setShippingInfo(
            (prev: EditOrderShippingInfoData): EditOrderShippingInfoData => ({
                ...prev,
                locality_name: locality.full_name_with_path,
                locality_id: locality.id,
            }),
        );

    const setDeliveryMethod = (
        event: React.ChangeEvent<HTMLSelectElement>,
    ): void => {
        const deliveryMethod = deliveryMethods.get(
            +event.target.value,
        ) as DeliveryMethodModel;

        setShippingInfo(
            (prev: EditOrderShippingInfoData): EditOrderShippingInfoData => ({
                ...prev,
                delivery_method_id: deliveryMethod.id,
                delivery_method_title: deliveryMethod.title,
                delivery_point_id:
                    prev.delivery_method_id !== deliveryMethod.id
                        ? null
                        : prev.delivery_method_id,
                delivery_point_address:
                    prev.delivery_method_id !== deliveryMethod.id
                        ? ``
                        : prev.delivery_point_address,
                postcode: deliveryMethod.has_delivery_points
                    ? prev.postcode
                    : null,
                street: deliveryMethod.has_delivery_points ? prev.street : null,
                house: deliveryMethod.has_delivery_points ? prev.house : null,
                flat: deliveryMethod.has_delivery_points ? prev.flat : null,
            }),
        );
    };

    const changePaymentMethod = (
        event: React.ChangeEvent<HTMLSelectElement>,
    ): void =>
        setPaymentMethod(
            paymentMethods.get(+event.target.value) as PaymentMethodModel,
        );

    const setCountry = (event: React.ChangeEvent<HTMLSelectElement>): void => {
        const country = countries.get(+event.target.value) as CountryModel;

        setShippingInfo(
            (prev: EditOrderShippingInfoData): EditOrderShippingInfoData => ({
                ...prev,
                country_id: country.id,
                country_name: country.name,
            }),
        );
    };

    const setDeliveryPoint = (point: DeliveryPointModel): void => {
        setShippingInfo(
            (prev: EditOrderShippingInfoData): EditOrderShippingInfoData => ({
                ...prev,
                delivery_point_id: point.id,
                delivery_point_address: point.address,
            }),
        );
    };

    return (
        <BaseModal
            closeModal={closeModal}
            title="Детали доставки и оплаты"
            body={
                <>
                    <div>
                        <h4>Данные получателя</h4>

                        <div className="row mb-5">
                            <Input
                                className={{ block: `col` }}
                                label="Имя"
                                defaultValue={shippingInfo.name || undefined}
                                name="name"
                                onChange={setShippingInfoField}
                            />
                            <Input
                                className={{ block: `col` }}
                                label="Фамилия"
                                defaultValue={
                                    shippingInfo.family_name || undefined
                                }
                                name="family_name"
                                onChange={setShippingInfoField}
                            />
                            <Input
                                className={{ block: `col` }}
                                label="Отчество"
                                defaultValue={
                                    shippingInfo.patronymic || undefined
                                }
                                name="patronymic"
                                onChange={setShippingInfoField}
                            />
                        </div>

                        <div className="row mb-5">
                            <Input
                                className={{ block: `col` }}
                                label="Email"
                                defaultValue={shippingInfo.email || undefined}
                                name="email"
                                onChange={setShippingInfoField}
                            />
                            <Input
                                className={{ block: `col` }}
                                label="Телефон"
                                defaultValue={shippingInfo.phone || undefined}
                                name="phone"
                                onChange={setShippingInfoField}
                            />
                        </div>

                        {!deliveryMethod.has_delivery_points && (
                            <>
                                <div className="row mb-5">
                                    <Input
                                        className={{ block: `col` }}
                                        label="Индекс"
                                        defaultValue={
                                            shippingInfo.postcode || undefined
                                        }
                                        name="postcode"
                                        onChange={setShippingInfoField}
                                    />
                                    <Input
                                        className={{ block: `col` }}
                                        label="Улица"
                                        defaultValue={
                                            shippingInfo.street || undefined
                                        }
                                        name="street"
                                        onChange={setShippingInfoField}
                                    />
                                </div>

                                <div className="row mb-5">
                                    <Input
                                        className={{ block: `col` }}
                                        label="Дом"
                                        defaultValue={
                                            shippingInfo.house || undefined
                                        }
                                        name="house"
                                        onChange={setShippingInfoField}
                                    />
                                    <Input
                                        className={{ block: `col` }}
                                        label="Дом"
                                        defaultValue={
                                            shippingInfo.flat || undefined
                                        }
                                        name="flat"
                                        onChange={setShippingInfoField}
                                    />
                                </div>
                            </>
                        )}
                    </div>
                    <div className="mb-5">
                        <h4>Способ оплаты</h4>
                        <select
                            className="form-control"
                            defaultValue={paymentMethod?.id || undefined}
                            onChange={changePaymentMethod}
                        >
                            {[...paymentMethods].map(([, method]) => (
                                <option key={method.id} value={method.id}>
                                    {method.title}
                                </option>
                            ))}
                            {!paymentMethod?.id && (
                                <option key={0} value="">
                                    Не указан
                                </option>
                            )}
                        </select>
                    </div>

                    <div>
                        <h4>Адрес доставки</h4>
                        <div className="row mb-5">
                            <div className="col">
                                <label className="form-label">Страна</label>
                                <select
                                    className="form-control"
                                    defaultValue={shippingInfo?.country_id}
                                    name="country_id"
                                    onChange={setCountry}
                                >
                                    {[...countries].map(([, country]) => (
                                        <option
                                            key={country.id}
                                            value={country.id}
                                        >
                                            {country.name}
                                        </option>
                                    ))}
                                </select>
                            </div>

                            <LocalitiesSearch
                                countryId={shippingInfo?.country_id || 0}
                                label="Город"
                                defaultLocalityId={
                                    shippingInfo?.locality_id || null
                                }
                                defaultLocalityName={
                                    shippingInfo?.locality_name || null
                                }
                                onChange={setLocality}
                            />
                        </div>

                        <div className="row mb-5">
                            <div className="col">
                                <label className="form-label">
                                    Способ доставки
                                </label>
                                <select
                                    className="form-control"
                                    defaultValue={
                                        shippingInfo?.delivery_method_id ||
                                        undefined
                                    }
                                    onChange={setDeliveryMethod}
                                >
                                    {[...deliveryMethods].map(
                                        ([, { id, title }]) => (
                                            <option key={id} value={id}>
                                                {title}
                                            </option>
                                        ),
                                    )}
                                </select>
                            </div>
                        </div>

                        {deliveryMethod.has_delivery_points && (
                            <DeliveryPoints
                                localityId={shippingInfo.locality_id}
                                deliveryMethodId={
                                    shippingInfo.delivery_method_id
                                }
                                deliveryPointId={shippingInfo.delivery_point_id}
                                setDeliveryPoint={setDeliveryPoint}
                            />
                        )}
                    </div>
                </>
            }
            footer={
                <>
                    <button className="btn btn-secondary" onClick={closeModal}>
                        Закрыть
                    </button>
                    <button className="btn btn-primary" onClick={submit}>
                        Подтвердить
                    </button>
                </>
            }
        />
    );
}

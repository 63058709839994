import React, { useMemo } from "react";
import repeat from "lodash/repeat";

import { useLanguageContext } from "@admin/components/LanguagesContext";
import MultiLanguages from "@admin/components/MultiLanguage";
import Select, { type Option } from "@admin/components/Select";
import { AboutPageModel } from "@admin/domain/uniquePages/models/AboutPageModel";
import { type UniquePageProps } from "../index";
import ImageInput from "@admin/components/ImageInput";

export default function AboutPageProductsPrideOfOurCompany({
    page,
}: UniquePageProps<AboutPageModel>) {
    const currentLanguage = useLanguageContext().currentLanguage;

    const categories: Option[] = [];
    page.payload.categories?.forEach((category) => {
        const textsMap = new Map(
            category.texts.map((text) => [text.language, text]),
        );
        categories.push({
            value: category.id,
            label: `${repeat(`-`, category.depth)} ${textsMap.get(currentLanguage)?.title || category.id}`,
        });
    });

    const banners = useMemo(
        () =>
            page.contentObject.products_pride_of_our_company__banners || [
                { file: undefined, category: undefined },
                { file: undefined, category: undefined },
                { file: undefined, category: undefined },
                { file: undefined, category: undefined },
            ],
        [page.contentObject.products_pride_of_our_company__banners],
    );

    return (
        <div className="mb-10">
            <h4 className="mb-5">Продукты - гордость нашей компании</h4>
            <MultiLanguages
                languageElement={(language) => (
                    <div>
                        <div className="mb-5">
                            <label className="form-label">Заголовок</label>
                            <input
                                className="form-control"
                                name={`content[products_pride_of_our_company__title][${language}]`}
                                defaultValue={
                                    page.contentObject
                                        .products_pride_of_our_company__title[
                                        language
                                    ] || ``
                                }
                            />
                        </div>
                        <div className="mb-5">
                            <label className="form-label">
                                Описание (Текст под заголовком)
                            </label>
                            <textarea
                                className="form-control"
                                name={`content[products_pride_of_our_company__description][${language}]`}
                                defaultValue={
                                    page.contentObject
                                        .products_pride_of_our_company__description[
                                        language
                                    ] || ``
                                }
                            />
                        </div>
                    </div>
                )}
            />
            <div className="row row-cols-auto">
                {banners.map((banner, index) => (
                    <div key={index} className="col mb-5">
                        <ImageInput
                            label="Изображение"
                            name={`content[products_pride_of_our_company__banners][${index}][file_id]`}
                            defaultValue={banner.file}
                            width="320px"
                            height="440px"
                        />

                        <Select
                            label="Категория"
                            options={categories}
                            name={`content[products_pride_of_our_company__banners][${index}][category_id]`}
                            defaultValue={banner.category?.id}
                            required
                        />
                    </div>
                ))}
            </div>
        </div>
    );
}

import React from "react";
import { ProductAttributesModel } from "@admin/domain/products/models/edit/ProductAttributesModel";
import Text from "./Text";
import LabelTypes from "./LabelTypes";
import CheckBox from "@admin/components/CheckBox";
import ProductMediaFiles from "./ProductMediaFiles/index";
import MultiLanguages from "@admin/components/MultiLanguage";

type ProductAttributesProps = {
    productAttributes: ProductAttributesModel;
};

export default function ProductAttributes(props: ProductAttributesProps) {
    return (
        <div className="rounded border p-10 mb-5">
            <h2 className="mb-5">Атрибуты товара</h2>

            <MultiLanguages
                languageElement={(language) => (
                    <Text
                        language={language}
                        texts={props.productAttributes.text}
                    />
                )}
            />

            <ProductMediaFiles
                productMediaFile={props.productAttributes.files}
            />

            <LabelTypes labelTypes={props.productAttributes.label_types} />

            <CheckBox
                name="product_attributes[is_public]"
                label="Показывать"
                defaultChecked={props.productAttributes.is_public}
            />

            <CheckBox
                name="product_attributes[honest_sign]"
                label="(Честный знак) Признак маркировки товара"
                defaultChecked={props.productAttributes.honest_sign}
            />

            <CheckBox
                name="product_attributes[not_medicinal_product]"
                label="Не является лекарственным средством"
                defaultChecked={props.productAttributes.not_medicinal_product}
            />

            <CheckBox
                name="product_attributes[is_not_discountable]"
                label="Не применять скидку"
                defaultChecked={props.productAttributes.is_not_discountable}
            />

            <CheckBox
                name="product_attributes[can_be_ordered]"
                label="Может быть добавлен в корзину покупателем"
                defaultChecked={props.productAttributes.can_be_ordered}
            />
        </div>
    );
}

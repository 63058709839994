import { createContext, type ReactNode, useContext, useState } from "react";

type InvalidFields = Set<string>;

type ContextContent = {
    invalidFields: InvalidFields;
    setInvalidFields: (value: InvalidFields) => void;
};

const Context = createContext<ContextContent>({
    invalidFields: new Set(),
    setInvalidFields: () => undefined,
});

function useInvalidFields() {
    return useContext(Context).invalidFields;
}

export function useIsInvalid(fieldName: string | undefined): boolean {
    if (!fieldName) {
        return false;
    }

    let alternativeName = ``;

    if (fieldName.slice(-2) === `[]`) {
        alternativeName = fieldName.slice(0, -2);
    }

    const invalidFields = useInvalidFields();

    return (
        invalidFields.has(fieldName) ||
        Boolean(alternativeName && invalidFields.has(alternativeName))
    );
}

export function useSetInvalidFields() {
    return useContext(Context).setInvalidFields;
}

export default function FormErrorsContext({
    children,
}: {
    children: ReactNode;
}) {
    const [invalidFields, setInvalidFields] = useState<InvalidFields>(
        new Set(),
    );
    const value = { invalidFields, setInvalidFields };

    return <Context.Provider value={value}>{children}</Context.Provider>;
}

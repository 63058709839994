import React from "react";
import { v4 as uuid } from "uuid";

import { ArticleBlockType } from "@admin/domain/articles/enums/ArticleBlockType";
import { type ArticleContentBlockModel } from "@admin/domain/articles/models/ArticleContentBlockModel";
import { OPTIONS } from "@admin/pages/ArticleEditor/Block";
import OptionsModal from "@admin/components/OptionsModal";

type Props = {
    show: boolean;
    close: () => void;
    onSubmit: (data: ArticleContentBlockModel) => void;
};

export default function AddBlockModal({ show, close, onSubmit }: Props) {
    const submit = (value: string): void => {
        onSubmit({
            id: uuid(),
            /** @ts-expect-error comment */
            type: value,
        });
    };

    return (
        <OptionsModal
            title={`Выберите тип блока`}
            options={OPTIONS}
            defaultValue={ArticleBlockType.TitleWithTextImage}
            show={show}
            onSubmit={submit}
            close={close}
        />
    );
}

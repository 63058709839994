import React, { useState } from "react";
import { v4 as uuid } from "uuid";
import Modal from "react-bootstrap/Modal";

import { ContentBlockModel } from "@admin/domain/content/models/ContentBlockModel";
import { ContentBlockType } from "@admin/domain/content/enums/ContentBlockType";
import TYPES from "./types";

type Props = {
    showModal: boolean;
    closeModal: () => void;
    onSubmit: (data: ContentBlockModel) => void;
};

export default function AddBlockModal({
    showModal,
    closeModal,
    onSubmit,
}: Props) {
    const [blockType, setBlockType] = useState<ContentBlockType>(
        ContentBlockType.Image,
    );

    const submit = (): void => {
        const newBlockData = {
            id: uuid(),
            type: blockType,
            value: undefined,
        };

        onSubmit(newBlockData);

        closeModal();
    };

    return (
        <Modal centered show={showModal} onHide={closeModal}>
            <Modal.Header>
                <Modal.Title>Добавление блока</Modal.Title>
            </Modal.Header>

            <Modal.Body>
                {Object.entries(TYPES).map(
                    ([checkboxBlockType, label]: [string, string]) => (
                        <div
                            className="mb-4"
                            key={`checkbox-${checkboxBlockType}`}
                        >
                            <div className="form-check form-check-custom form-check-solid">
                                <input
                                    id={`checkbox-${checkboxBlockType}`}
                                    className="form-check-input"
                                    type="radio"
                                    checked={blockType === checkboxBlockType}
                                    onChange={() =>
                                        setBlockType(
                                            checkboxBlockType as ContentBlockType,
                                        )
                                    }
                                />
                                <label
                                    className="form-check-label"
                                    htmlFor={`checkbox-${checkboxBlockType}`}
                                >
                                    {label}
                                </label>
                            </div>
                        </div>
                    ),
                )}
            </Modal.Body>

            <Modal.Footer>
                <button className="btn btn-secondary" onClick={closeModal}>
                    Закрыть
                </button>
                <button className="btn btn-primary" onClick={submit}>
                    Добавить
                </button>
            </Modal.Footer>
        </Modal>
    );
}

import React from "react";
import BaseModal from "@admin/components/Modal";

type Props = {
    closeModal: () => void;
};

export default function OrderChangesFrom1cModal({ closeModal }: Props) {
    return (
        <BaseModal
            closeModal={closeModal}
            title="Заказ изменен из 1С"
            body={
                <p>
                    Заказ был изменен из 1С и более недоступен для
                    редактирования посредством CMS. После нажатия на кнопку
                    “Понятно” вы будете возвращены в список заказов
                </p>
            }
            footer={
                <button className="btn btn-primary" onClick={closeModal}>
                    Понятно
                </button>
            }
        />
    );
}

import React, { useState } from "react";
import Sortable from "@admin/components/Sortable";
import Input from "@admin/components/Input";
import {
    FooterBlockModel,
    FooterLinkModel,
} from "@admin/domain/footer/Models/FooterBlockModel";
import BaseModal from "@admin/components/Modal";

type Props = {
    closeModal: () => void;
    onSubmit: (data: FooterBlockModel) => void;
    editableBlock: FooterBlockModel;
};

export default function FooterBlocksModal({
    closeModal,
    editableBlock,
    onSubmit,
}: Props) {
    const [block, setBlock] = useState<FooterBlockModel>(editableBlock);

    const updateLink = (
        event: React.ChangeEvent<HTMLInputElement>,
        index: number,
    ): void => {
        setBlock({
            ...block,
            value: block.value.map(
                (footerLink: FooterLinkModel, prevIndex: number) => {
                    if (prevIndex === index) {
                        footerLink[event.target.name] = event.target.value;
                    }

                    return footerLink;
                },
            ),
        });
    };

    const addLink = (): void => {
        setBlock({
            ...block,
            value: [
                ...block.value,
                {
                    id: 0,
                    title: ``,
                    url: ``,
                },
            ],
        });
    };

    const deleteLink = (index: number): void => {
        setBlock({
            ...block,
            value: block.value.filter((_, prevIndex) => prevIndex !== index),
        });
    };

    const setLinks = (links: FooterLinkModel[]): void =>
        setBlock({
            ...block,
            value: links,
        });

    const submit = (): void => {
        onSubmit(block);
        closeModal();
    };

    return (
        <BaseModal
            closeModal={closeModal}
            title={`${editableBlock.id ? `Обновление ` : `Добавление `} блока`}
            body={
                <>
                    <Input
                        label="Название блока"
                        id="block_title"
                        defaultValue={editableBlock?.title}
                        onChange={(event): void =>
                            setBlock({
                                ...block,
                                title: event.target.value,
                            })
                        }
                        required
                    />

                    <h3>Страницы блока</h3>
                    <Sortable list={block.value} setList={setLinks}>
                        {block.value?.map(
                            (footerLink: FooterLinkModel, index: number) => (
                                <div
                                    key={footerLink.id}
                                    className="card rounded border mb-4"
                                >
                                    <div className="card-body">
                                        <Input
                                            label="Название ссылки"
                                            defaultValue={footerLink.title}
                                            name="title"
                                            onChange={(event): void =>
                                                updateLink(event, index)
                                            }
                                            required
                                        />

                                        <Input
                                            label="Url ссылки"
                                            defaultValue={footerLink.url}
                                            name="url"
                                            onChange={(event): void =>
                                                updateLink(event, index)
                                            }
                                            required
                                        />
                                    </div>
                                    <div>
                                        <a
                                            className="card-link text-danger"
                                            onClick={(): void =>
                                                deleteLink(index)
                                            }
                                        >
                                            Удалить
                                        </a>
                                    </div>
                                </div>
                            ),
                        )}
                    </Sortable>
                    <div className="mb-5 d-flex">
                        <div className="me-5">
                            <button
                                type="button"
                                className="btn btn-success"
                                onClick={addLink}
                            >
                                Добавить страницу
                            </button>
                        </div>
                    </div>
                </>
            }
            footer={
                <>
                    <button className="btn btn-secondary" onClick={closeModal}>
                        Закрыть
                    </button>
                    <button className="btn btn-primary" onClick={submit}>
                        {editableBlock ? `Обновить` : `Сохранить`}
                    </button>
                </>
            }
        />
    );
}

import ValidationError from "./ValidationError";
import ResponseError from "./ResponseError";
import { HTTP_OK, HTTP_UNPROCESSABLE_ENTITY } from "@admin/utils/httpStatuses";

export interface ResponseJson<T> {
    description: string;
    payload: T;
}

export type ValidationResponseJson = {
    description: string;
    payload: {
        messages: { [fieldName: string]: Array<string> };
    };
};

const parseResponse = async <T>(
    response: Response,
): Promise<ResponseJson<T>> => {
    let json: ResponseJson<T>;

    try {
        json = await response.json();
    } catch (error) {
        throw new ResponseError(
            `The response from the server could not be parsed`,
        );
    }

    if (response.status === HTTP_UNPROCESSABLE_ENTITY) {
        throw new ValidationError(json as ValidationResponseJson);
    } else if (response.status !== HTTP_OK) {
        throw new ResponseError(json.description, response.status);
    }

    return json as ResponseJson<T>;
};

export default parseResponse;

import React, { useState } from "react";

import { type UniquePageProps } from "../index";
import {
    AdditionalMaterialsBannerModel,
    AdditionalMaterialsModel,
    AdditionalMaterialsPageModel,
} from "@admin/domain/uniquePages/models/AdditionalMaterialsModel";
import AdditionalMaterialsBannerBlockItem from "@admin/pages/UniquePageEditor/AdditionalInfo/AdditionalMaterialsBannerBlockItem";
import { v4 as uuid } from "uuid";
import AdditionalMaterialsPageBlockItem from "@admin/pages/UniquePageEditor/AdditionalInfo/AdditionalMaterialsPageBlockItem";

export default function AdditionalMaterials({
    page,
}: UniquePageProps<AdditionalMaterialsModel>) {
    const initBanners = (page.contentObject.banners || []).map((value) => ({
        ...value,
        id: uuid(),
    }));
    const initPages = (page.contentObject.pages || []).map((value) => ({
        ...value,
        id: uuid(),
    }));
    const [pages, setPages] =
        useState<AdditionalMaterialsPageModel[]>(initPages);
    const [banners, setBanners] =
        useState<AdditionalMaterialsBannerModel[]>(initBanners);

    const addPage = (): void =>
        setPages([
            ...pages,
            {
                id: uuid(),
                title: {},
                url: null,
            },
        ]);

    const deletePage = (id: string): void => {
        setPages(pages.filter((item) => item.id !== id));
    };
    const addBanner = (): void =>
        setBanners([
            ...banners,
            {
                id: uuid(),
                file: null,
                url: null,
            },
        ]);

    const deleteBanner = (id: string): void => {
        setBanners(banners.filter((item) => item.id !== id));
    };

    return (
        <div>
            <h2>Редактор ссылок на страницы</h2>
            <div className="row">
                {pages.map(
                    (page: AdditionalMaterialsPageModel, pageIndex: number) => {
                        return (
                            <div
                                className="rounded border p-10 m-5"
                                key={pageIndex}
                            >
                                <AdditionalMaterialsPageBlockItem
                                    key={page.id}
                                    index={pageIndex}
                                    title={page?.title || ``}
                                    url={page?.url || ``}
                                    remove={() => deletePage(page.id)}
                                />
                            </div>
                        );
                    },
                )}
            </div>

            <button
                type="button"
                className="btn btn-primary mb-5"
                onClick={addPage}
            >
                Добавить страницу
            </button>

            <h2>Редактор баннеров</h2>
            <div className="row">
                {banners.map(
                    (
                        banner: AdditionalMaterialsBannerModel,
                        bannerIndex: number,
                    ) => {
                        return (
                            <div
                                className="rounded border p-10 m-5"
                                key={bannerIndex}
                            >
                                <AdditionalMaterialsBannerBlockItem
                                    key={banner.id}
                                    index={bannerIndex}
                                    file={banner?.file || null}
                                    url={banner?.url || ``}
                                    remove={() => deleteBanner(banner.id)}
                                />
                            </div>
                        );
                    },
                )}
            </div>
            {banners.length < 10 && (
                <button
                    type="button"
                    className="btn btn-primary mb-5"
                    onClick={addBanner}
                >
                    Добавить баннер
                </button>
            )}
        </div>
    );
}

import React, { useState } from "react";
import ReactQuill from "react-quill";
import Quill from "quill";
// @ts-ignore
import ImageUploader from "quill-image-uploader";

import { useIsInvalid } from "@admin/components/FormErrorsContext";

import "react-quill/dist/quill.snow.css";
import "quill-image-uploader/dist/quill.imageUploader.min.css";

const defaultToolbar = [
    [{ header: [1, 2, 3, 4, 5, 6, false] }],
    [`bold`, `italic`, `blockquote`, `link`],
    [{ list: `ordered` }, { list: `bullet` }],
    [`clean`],
];

Quill.register(`modules/imageUploader`, ImageUploader);

export type QuillEditorProps = {
    initHtml?: string | undefined | null;
    onUploadImage?: (file: File) => void;
    label: string;
    name: string;
};

const QuillEditor: React.FC<QuillEditorProps> = ({
    label,
    name,
    initHtml = ``,
    onUploadImage = null,
}) => {
    const [html, setHtml] = useState<string>(initHtml || ``);

    const isInvalid = useIsInvalid(name);

    const modules = {
        toolbar: defaultToolbar,
    };

    if (onUploadImage) {
        // @ts-ignore
        modules.imageUploader = {
            upload: onUploadImage,
        };
    }

    return (
        <div className="mb-5">
            {label && <label className="form-label">{label}</label>}

            <ReactQuill
                value={html}
                onChange={setHtml}
                modules={modules}
                className={`form-control` + (isInvalid ? ` is-invalid` : ``)}
            />

            <input type="hidden" value={html} name={name} />
        </div>
    );
};

export default QuillEditor;

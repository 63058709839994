import React from "react";
import { OrderType } from "@admin/domain/orders/enums/OrderType";
import RadioButton from "@admin/components/RadioButton";
import { type UserModel } from "@admin/domain/users/models/UserModel";
import UsersSearch from "@admin/components/UsersSearch";
import ConsolidatedOrders from "@admin/pages/OrderEditor/Creation/ConsolidatedOrders";
import { ConsolidatedOrderData } from "@admin/domain/orders/dto/ConsolidatedOrderData";
import { storeOrder } from "@admin/domain/orders/api";
import { useLoaderContext } from "@admin/components/LoaderContext";
import Select from "@admin/components/Select";
import { CurrencyModel } from "@admin/domain/currencies/models/CurrencyModel";

type Props = {
    currencies: CurrencyModel[];
};

export default function Creation({ currencies }: Props) {
    const [orderType, setOrderType] = React.useState<OrderType>(
        OrderType.Simple,
    );

    const [orderCreator, setOrderCreator] = React.useState<UserModel | null>(
        null,
    );

    const [consolidatedOrder, setConsolidatedOrder] =
        React.useState<ConsolidatedOrderData | null>(null);

    const [currency, setCurrency] = React.useState<string>(
        (
            currencies.find(
                (cur: CurrencyModel): boolean => cur.is_base,
            ) as CurrencyModel
        ).code,
    );

    const { isLoading, request } = useLoaderContext();

    const store = async (): Promise<void> => {
        request(async (): Promise<void> => {
            const { id } = await storeOrder({
                creator_id: orderCreator?.id || 0,
                type: orderType,
                consolidated_order_id: consolidatedOrder?.id || null,
                currency,
            });

            location.replace(`/admin/orders/${id}`);
        });
    };

    return (
        <div className="rounded border p-10 my-5">
            <h4>Создание заказа</h4>

            <p>Тип заказа</p>
            <RadioButton
                label="Обычный"
                checked={orderType === OrderType.Simple}
                onChange={(): void => {
                    setOrderType(OrderType.Simple);
                    setConsolidatedOrder(null);
                }}
            />
            <RadioButton
                label="Подзаказ"
                checked={orderType === OrderType.Consolidated}
                onChange={(): void => setOrderType(OrderType.Consolidated)}
            />
            <UsersSearch onSelectUser={setOrderCreator} label="Автор заказа" />
            {orderType === OrderType.Consolidated && orderCreator && (
                <ConsolidatedOrders
                    creator={orderCreator}
                    setSelectedConsolidatedOrder={setConsolidatedOrder}
                />
            )}
            <Select
                label="Валюта"
                options={currencies.map((currency: CurrencyModel) => ({
                    value: currency.code,
                    label: `${currency.title} (${currency.symbol})`,
                }))}
                value={currency}
                onChange={(e): void => setCurrency(e.target.value)}
                prependEmptyOption={false}
            />
            <div
                className="d-flex justify-content-end"
                title={
                    !orderCreator
                        ? `Необходимо выбрать автора заказа`
                        : undefined
                }
            >
                <button
                    className="btn btn-primary"
                    onClick={store}
                    disabled={!orderCreator || isLoading}
                >
                    Создать
                </button>
            </div>
        </div>
    );
}

import React, { useState } from "react";
import { v4 as uuid } from "uuid";

import { DropDownElementValueModel } from "@admin/domain/articles/models/ArticleBlockValueModel";
import { ArticleContentBlockModel } from "@admin/domain/articles/models/ArticleContentBlockModel";
import { ArticleBlockType } from "@admin/domain/articles/enums/ArticleBlockType";
import MultiLanguages from "@admin/components/MultiLanguage";
import DropDownElementsList from "./DropDownElementsList";

type List = Partial<DropDownElementValueModel>;

type Props = {
    block: ArticleContentBlockModel & {
        type: ArticleBlockType.DropDownElements;
    };
    index: number;
};

export default function DropDownElementsBlock({
    block: { value },
    index,
}: Props) {
    const initList = (value?.dropdown_elements || []).map((value) => ({
        ...value,
        id: uuid(),
    }));
    const [list, setEl] = useState<List[]>(initList);

    return (
        <>
            <MultiLanguages
                languageElement={(language) => (
                    <>
                        <div className="mb-5">
                            <label className="form-label">Описание</label>
                            <input
                                className="form-control"
                                name={`blocks[${index}][value][title][${language}]`}
                                defaultValue={value?.title?.[language] || ``}
                            />
                        </div>

                        <div className="mb-5">
                            <label className="form-label">Текст</label>
                            <input
                                className="form-control"
                                name={`blocks[${index}][value][text][${language}]`}
                                defaultValue={value?.text?.[language] || ``}
                            />
                        </div>
                    </>
                )}
            />

            <div className="row row-cols-auto">
                {list.map((el, itemIndex) => (
                    <div key={el.id} className="col mb-7">
                        <MultiLanguages
                            languageElement={(language) => (
                                <>
                                    <div className="mb-7">
                                        <label className="form-label">
                                            Заголовок
                                        </label>
                                        <textarea
                                            className="form-control"
                                            rows={5}
                                            cols={50}
                                            name={`blocks[${index}][value][dropdown_elements][${itemIndex}][title][${language}]`}
                                            defaultValue={
                                                el?.title?.[language] || ``
                                            }
                                        />
                                    </div>
                                </>
                            )}
                        />

                        <DropDownElementsList
                            list={el as DropDownElementValueModel}
                            index={itemIndex}
                            blockIndex={index}
                        />

                        <div>
                            <a
                                className="card-link text-danger"
                                onClick={() => {
                                    setEl((prev) =>
                                        prev.filter(
                                            (value) => value.id !== el.id,
                                        ),
                                    );
                                }}
                            >
                                Удалить
                            </a>
                        </div>
                    </div>
                ))}
            </div>

            <div className="mb-5 d-flex">
                <div className="me-5">
                    <button
                        type="button"
                        className="btn btn-success"
                        onClick={() =>
                            setEl((elState) => [
                                ...elState,
                                {
                                    id: uuid(),
                                    title: undefined,
                                },
                            ])
                        }
                    >
                        Добавить
                    </button>
                </div>
            </div>
        </>
    );
}

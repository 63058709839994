import React, { useMemo } from "react";
import { v4 as uuid } from "uuid";

import type { BusinessPageModel } from "@admin/domain/uniquePages/models/BusinessPageModel";
import type { UniquePageProps } from "../index";
import MultiLanguages from "@admin/components/MultiLanguage";
import ImageInput from "@admin/components/ImageInput";

export default function BusinessPageImagesWithText({
    page,
}: UniquePageProps<BusinessPageModel>) {
    const banners: BusinessPageModel[`images_with_text__banners`] = useMemo(
        () =>
            (page.contentObject.images_with_text__banners || []).map(
                (value) => ({
                    ...value,
                    id: uuid(),
                    file: null,
                }),
            ),
        [page.contentObject.images_with_text__banners],
    );

    return (
        <div className="mb-10">
            <h4 className="mb-5">
                Блок с изображениями и текстом в шахматном порядке
            </h4>
            <div>
                <MultiLanguages
                    languageElement={(language) => (
                        <div>
                            <div className="mb-5">
                                <label className="form-label">Заголовок</label>
                                <input
                                    className="form-control"
                                    name={`content[images_with_text__title][${language}]`}
                                    defaultValue={
                                        page.contentObject
                                            .images_with_text__title?.[
                                            language
                                        ] || ``
                                    }
                                />
                            </div>
                            <div className="mb-5">
                                <label className="form-label">Описание</label>
                                <textarea
                                    className="form-control"
                                    name={`content[images_with_text__description][${language}]`}
                                    defaultValue={
                                        page.contentObject
                                            .images_with_text__description?.[
                                            language
                                        ] || ``
                                    }
                                />
                            </div>
                        </div>
                    )}
                />
            </div>
            <div className="row row-cols-auto">
                {banners.map((banner, index) => (
                    <div key={banner.id} className="col mb-5">
                        <MultiLanguages
                            languageElement={(language) => (
                                <div>
                                    <div className="mb-5">
                                        <label className="form-label">
                                            Заголовок
                                        </label>
                                        <input
                                            className="form-control"
                                            name={`content[images_with_text__banners][${index}][title][${language}]`}
                                            defaultValue={
                                                banner.title?.[language] || ``
                                            }
                                        />
                                    </div>
                                    <div className="mb-5">
                                        <label className="form-label">
                                            Текст
                                        </label>
                                        <textarea
                                            className="form-control"
                                            name={`content[images_with_text__banners][${index}][description][${language}]`}
                                            defaultValue={
                                                banner.description?.[
                                                    language
                                                ] || ``
                                            }
                                        />
                                    </div>
                                    <div className="mb-5">
                                        <label className="form-label">
                                            Текст кнопки
                                        </label>
                                        <input
                                            className="form-control"
                                            name={`content[images_with_text__banners][${index}][button_text][${language}]`}
                                            defaultValue={
                                                banner.button_text?.[
                                                    language
                                                ] || ``
                                            }
                                        />
                                    </div>
                                </div>
                            )}
                        />

                        <div className="mb-5">
                            <label className="form-label">
                                Ссылка для кнопки
                            </label>
                            <input
                                className="form-control"
                                name={`content[images_with_text__banners][${index}][url]`}
                                defaultValue={banner.url}
                            />
                        </div>
                        <div key={index} className="col mb-5">
                            <ImageInput
                                label="Изображение"
                                name={`content[images_with_text__banners][${index}][file_id]`}
                                defaultValue={banner.file}
                                width="300px"
                            />
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
}

import { ResponseErrorInterface } from "./ResponseErrorInterface";

class ResponseError extends Error implements ResponseErrorInterface {
    readonly code: number;
    readonly errors: string[];

    constructor(description: string, code = 500) {
        super(description);
        this.errors = [description];
        this.code = code;

        Object.setPrototypeOf(this, ResponseError.prototype);
    }
}

export default ResponseError;

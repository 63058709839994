import React from "react";
import { OrderProductModel } from "@admin/domain/orders/models/OrderProductModel";
import { useLoaderContext } from "@admin/components/LoaderContext";
import { updateOrderProducts } from "@admin/domain/orders/api";
import { EditOrderData } from "@admin/domain/orders/dto/EditOrderData";
import { useOrderHooks } from "@admin/pages/OrderEditor/hooks";

type Props = {
    order: EditOrderData;
};

export default function ProductsList({ order }: Props) {
    const { actualizeOrder } = useOrderHooks();
    const { isLoading, request } = useLoaderContext();

    const changeQuantity = async (
        orderProduct: OrderProductModel,
        quantity: number,
    ): Promise<void> => {
        if (isLoading) {
            return;
        }

        request(async (): Promise<void> => {
            await updateOrderProducts(order.id, [
                {
                    id: orderProduct.product_id,
                    quantity,
                },
            ]);

            await actualizeOrder();
        });
    };

    return (
        <>
            <div className="fw-bolder">Товары в заказе</div>
            <table className="table table-striped gy-7 gs-7 overflow-auto w-100 mb-5">
                <thead>
                    <tr>
                        <td scope="col">Название</td>
                        <td scope="col" className="text-center">
                            Артикул
                        </td>
                        <td scope="col" className="text-center">
                            Цена
                        </td>
                        <td
                            scope="col"
                            className="text-center"
                            style={{ width: `150px` }}
                        >
                            Базовая цена
                        </td>
                        <td
                            scope="col"
                            className="text-center"
                            style={{ width: `90px` }}
                        >
                            Кол-во
                        </td>
                        <td scope="col" className="text-center">
                            Баллы
                        </td>
                        <td scope="col" className="text-center">
                            Итого
                        </td>
                        <td
                            scope="col"
                            className="text-center"
                            style={{ width: `80px` }}
                        ></td>
                    </tr>
                </thead>
                <tbody>
                    {order.products.map((orderProduct: OrderProductModel) => (
                        <tr key={orderProduct.id}>
                            <td>
                                <a
                                    href={`/admin/products/${orderProduct.product_id}`}
                                >
                                    {orderProduct.title}
                                </a>
                            </td>
                            <td className="text-center">
                                {orderProduct.product.sku}
                            </td>
                            <td className="text-center">
                                {orderProduct.price / 100}
                                {` `}
                                {order.currency_symbol}
                            </td>
                            <td className="text-center">
                                {orderProduct.retail_price / 100}
                                {` `}
                                {order.currency_symbol}
                            </td>
                            <td className="text-center">
                                {order.is_editable && (
                                    <i
                                        className={`bi bi-dash-circle text-${isLoading ? `secondary` : `danger`} cursor-pointer me-3`}
                                        onClick={(): Promise<void> =>
                                            changeQuantity(
                                                orderProduct,
                                                orderProduct.quantity - 1,
                                            )
                                        }
                                    ></i>
                                )}

                                <span>{orderProduct.quantity}</span>
                                {order.is_editable && (
                                    <i
                                        className={`bi bi-dash-circle text-${isLoading ? `secondary` : `primary`} cursor-pointer ms-3`}
                                        onClick={(): Promise<void> =>
                                            changeQuantity(
                                                orderProduct,
                                                orderProduct.quantity + 1,
                                            )
                                        }
                                    ></i>
                                )}
                            </td>
                            <td className="text-center">
                                {orderProduct.quantity *
                                    orderProduct.points_reward}
                            </td>
                            <td className="text-center">
                                {(orderProduct.quantity * orderProduct.price) /
                                    100}
                                {` `}
                                {order.currency_symbol}
                            </td>
                            <td className="text-center">
                                {order.is_editable && (
                                    <i
                                        className={`bi bi-trash text-${isLoading ? `secondary` : `danger`} cursor-pointer`}
                                        onClick={(): Promise<void> =>
                                            changeQuantity(orderProduct, 0)
                                        }
                                    ></i>
                                )}
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </>
    );
}

import React from "react";

import type { MainPageModel } from "@admin/domain/uniquePages/models/MainPageModel";
import type { UniquePageProps } from "../index";
import MultiLanguages from "@admin/components/MultiLanguage";
import ImageInput from "@admin/components/ImageInput";

export default function MainPageAbout({
    page,
}: UniquePageProps<MainPageModel>) {
    const banners = page.contentObject.about__banner || [
        { title: {}, description: {}, file: null },
    ];

    return (
        <div className="mb-10">
            <h4 className="mb-5">Блок о компании</h4>
            <div>
                <MultiLanguages
                    languageElement={(language) => (
                        <>
                            <div className="mb-5">
                                <label className="form-label">Заголовок</label>
                                <input
                                    className="form-control"
                                    name={`content[about__title][${language}]`}
                                    defaultValue={
                                        page.contentObject.about__title?.[
                                            language
                                        ] || ``
                                    }
                                />
                            </div>

                            <div className="mb-5">
                                <label className="form-label">Описание</label>
                                <textarea
                                    className="form-control"
                                    name={`content[about__description][${language}]`}
                                    defaultValue={
                                        page.contentObject.about__description?.[
                                            language
                                        ] || ``
                                    }
                                />
                            </div>
                        </>
                    )}
                />

                {banners.map((banner, index) => (
                    <div key={index} className="col mb-5">
                        <ImageInput
                            label="Изображение"
                            name={`content[about__banner][${index}][file_id]`}
                            defaultValue={banner.file}
                            width="800px"
                        />
                    </div>
                ))}

                <div className="row row-cols-auto">
                    {page.contentObject.about__tabs?.map((tab, index) => (
                        <div key={tab.id} className="col mb-7">
                            <MultiLanguages
                                languageElement={(language) => (
                                    <>
                                        <div className="mb-7">
                                            <label className="form-label">
                                                Заголовок
                                            </label>
                                            <input
                                                className="form-control"
                                                type="text"
                                                name={`content[about__tabs][${index}][title][${language}]`}
                                                defaultValue={
                                                    tab.title?.[language] || ``
                                                }
                                            />
                                        </div>

                                        <div className="mb-7">
                                            <label className="form-label">
                                                Описание
                                            </label>
                                            <textarea
                                                className="form-control"
                                                rows={5}
                                                cols={50}
                                                name={`content[about__tabs][${index}][description][${language}]`}
                                                defaultValue={
                                                    tab.description?.[
                                                        language
                                                    ] || ``
                                                }
                                            />
                                        </div>
                                    </>
                                )}
                            />
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
}

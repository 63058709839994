import React from "react";
import TargetUserModal from "./TargetUserModal";
import { useOrderContext } from "../../OrderContext";

export default function TargetGeneralInfo() {
    const { order } = useOrderContext();
    const [showModal, setShowModal] = React.useState(false);

    return (
        <div className="rounded border p-6 mb-5">
            <div className="fw-bolder">На кого оформлен заказ:</div>
            <div className="ms-5">
                <div>
                    <span className="fw-bolder">ФИО: </span>
                    <a href="">{order.target.full_name}</a>
                </div>
                <div>
                    <span className="fw-bolder">Почта: </span>
                    {order.target.email}
                </div>
                <div>
                    <span className="fw-bolder">Личный номер: </span>
                    {order.target.personal_number}
                </div>
                <div>
                    <span className="fw-bolder">
                        Статус по маркетинг-плану:{` `}
                    </span>
                    {order.target_marketing_plan_status}
                </div>
                {order.is_editable && (
                    <a
                        className="d-block link link-primary"
                        href="#"
                        onClick={(
                            event: React.MouseEvent<HTMLAnchorElement>,
                        ): void => {
                            event.preventDefault();
                            setShowModal(true);
                        }}
                    >
                        Редактировать
                    </a>
                )}
                {order.is_editable && showModal && (
                    <TargetUserModal
                        closeModal={(): void => setShowModal(false)}
                    />
                )}
            </div>
        </div>
    );
}

import React from "react";
import { Collapse } from "react-bootstrap";

import { EditOrderData } from "@admin/domain/orders/dto/EditOrderData";
import SimpleOrder from "../SimpleOrder";
import { useOrderContext } from "@admin/pages/OrderEditor/OrderContext";
import AccountingAndAnaliticalDb from "../../AccountingAndAnaliticalDb";
import formatMoney from "@admin/domain/orders/formatMoney";

export default function ConsolidateOrder() {
    const { order } = useOrderContext();

    const [collapseState, setCollapseState] = React.useState<
        Map<number, boolean>
    >(
        new Map(
            order.sub_orders.map((order: EditOrderData) => [order.id, false]),
        ),
    );

    const toggleCollapse = (order: EditOrderData): void => {
        setCollapseState(
            new Map([
                ...collapseState,
                [order.id, !collapseState.get(order.id)],
            ]),
        );
    };

    return (
        <div>
            {order.sub_orders.map((subOrder: EditOrderData) => (
                <div key={subOrder.id} className="border rounded p-5 mb-3">
                    <div
                        className={`cursor-pointer ${collapseState.get(subOrder.id) ? `mb-5` : ``}`}
                        onClick={() => toggleCollapse(subOrder)}
                    >
                        Подзаказ №{subOrder.id}
                    </div>
                    <Collapse in={collapseState.get(subOrder.id)}>
                        <div>
                            <SimpleOrder order={subOrder} />
                            <AccountingAndAnaliticalDb order={subOrder} />
                        </div>
                    </Collapse>
                </div>
            ))}

            <div className="mb-5">
                <span className="fw-bolder">Доставка: </span>
                <span>
                    {order.delivery_cost > 0
                        ? formatMoney(order, `delivery_cost`)
                        : `бесплатно`}
                </span>
            </div>

            <div className="mb-5">
                <span className="fw-bolder">Итоговая стоимость: </span>
                <span>{formatMoney(order, `total_amount`)}</span>
            </div>

            <div className="mb-5">
                <span className="fw-bolder">Баллы: </span>
                <span>
                    {order.sub_orders.reduce(
                        (total: number, order: EditOrderData): number =>
                            total + order.total_points,
                        0,
                    )}
                </span>
            </div>
        </div>
    );
}

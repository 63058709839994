import React, { type ChangeEvent, useCallback } from "react";
import isFunction from "lodash/isFunction";

type Props = {
    name: string | (() => string);
    value: string | null;
    onChange: (value: string | null) => void;
    disabled: boolean;
};

export default function Link({ name, value, onChange, disabled }: Props) {
    const onUrlChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
        onChange(`${event.target.value}`.trim() || null);
    }, []);

    const remove = useCallback(() => {
        if (confirm(`Вы действительно хотите удалить ссылку?`)) {
            onChange(null);
        }
    }, []);

    if (disabled) {
        return null;
    }

    return (
        <div className="mb-5">
            <label className="form-label">Ссылка на YouTube или VK Видео</label>

            <div className="input-group">
                <input
                    className="form-control"
                    name={isFunction(name) ? name() : `${name}[url]`}
                    value={value || ``}
                    onChange={onUrlChange}
                />

                {value && (
                    <button
                        type="button"
                        className="btn btn-outline btn-outline-secondary"
                        onClick={remove}
                    >
                        <i
                            title="Удалить"
                            className="fa fa-trash text-danger"
                        />
                    </button>
                )}
            </div>
        </div>
    );
}

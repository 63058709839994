import React from "react";
import debounce from "lodash-es/debounce";
import AsyncSelect from "react-select/async";

import {
    type FoundAdmin,
    type FoundAdminsPayload,
    searchAdmins,
} from "@admin/domain/admins/api";
import { useIsInvalid } from "../FormErrorsContext";
import { SelectOption } from "@admin/utils/types";

type Props = {
    inputName: string;
    label?: string;
    adminId?: number;
    adminName?: string;
    onChange?: (adminId: number | undefined) => void;
};

export const AdminsSearch: React.FC<Props> = ({
    adminId,
    adminName,
    inputName,
    label,
    onChange = () => null,
}) => {
    const isInvalid = useIsInvalid(inputName);

    const debouncedSearch = debounce(
        (
            query: string,
            setOptionsCallback: (options: ReadonlyArray<SelectOption>) => void,
        ): void => {
            if (query.length < 2) {
                return;
            }

            searchAdmins(query).then(({ admins }: FoundAdminsPayload): void =>
                setOptionsCallback(
                    admins.map(
                        (admin: FoundAdmin): SelectOption => ({
                            value: admin.id,
                            label: admin.name + ` (` + admin.email + `)`,
                        }),
                    ),
                ),
            );
        },
        500,
    );

    return (
        <div className="mb-5">
            {label && <label className="form-label">{label}</label>}

            <AsyncSelect
                classNames={{
                    control: () =>
                        `form-control p-0` + (isInvalid ? ` is-invalid` : ``),
                }}
                placeholder="Поиск имени и email"
                loadOptions={debouncedSearch}
                defaultValue={
                    adminId
                        ? {
                              label: adminName,
                              value: adminId,
                          }
                        : undefined
                }
                name={inputName}
                isClearable
                required
                onChange={(option): void => onChange(option?.value as number)}
            />
        </div>
    );
};

export default AdminsSearch;

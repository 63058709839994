import React from "react";
import LanguagesContext from "@admin/components/LanguagesContext";
import LoaderContext from "@admin/components/LoaderContext";
import GeneralInfo from "./GeneralInfo";
import OrderContext from "./OrderContext";
import Composition from "./Composition";
import AccountingAndAnaliticalDb from "./AccountingAndAnaliticalDb";
import Fiscalization from "./Fiscalization";
import Logs from "./Logs";
import ErrorsModal from "./ErrorsModal";
import Creation from "@admin/pages/OrderEditor/Creation";

export default function OrderEditor() {
    const { paymentMethods, countries, deliveryMethods, order, currencies } =
        window.CMS;

    if (!paymentMethods || !countries || !deliveryMethods || !currencies) {
        throw new Error(`Недостаточно данных для редактирования заказа`);
    }

    if (!order) {
        return (
            <LoaderContext>
                <>
                    <Creation currencies={currencies} />
                    <ErrorsModal />
                </>
            </LoaderContext>
        );
    }

    return (
        <LoaderContext>
            <LanguagesContext>
                <OrderContext
                    order={order}
                    paymentMethods={paymentMethods}
                    countries={countries}
                    deliveryMethods={deliveryMethods}
                >
                    <h1>Редактирование заказа</h1>
                    <GeneralInfo />
                    <Composition />
                    <AccountingAndAnaliticalDb order={order} />
                    <Fiscalization />
                    <Logs />
                    <div className="d-flex justify-content-end">
                        <a className="btn btn-primary" href="/admin/orders">
                            Вернуться к списку
                        </a>
                    </div>
                    <ErrorsModal />
                </OrderContext>
            </LanguagesContext>
        </LoaderContext>
    );
}

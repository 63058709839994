import React, { ReactNode, useState } from "react";

import type { ContentBlockModel } from "@admin/domain/content/models/ContentBlockModel";
import { ContentBlockType } from "@admin/domain/content/enums/ContentBlockType";
import Sortable from "@admin/components/Sortable";
import TYPES from "./types";
import ImageBlock from "./Blocks/ImageBlock";
import VideoBlock from "./Blocks/VideoBlock";
import TextBlock from "./Blocks/TextBlock";
import PhotoSliderBlock from "./Blocks/PhotoSliderBlock";
import HeadingWithTextBlock from "./Blocks/HeadingWithTextBlock";
import HeadingWithListBlock from "./Blocks/HeadingWithListBlock";
import BGColorTextBlock from "./Blocks/BGColorTextBlock";
import PhotoWithTextSliderBlock from "./Blocks/PhotoWithTextSliderBlock";
import AddBlockModal from "./AddBlockModal";
import LeadersBlock from "./Blocks/LeadersBlock";
import ExpertOpinionBlock from "./Blocks/ExpertOpinionBlock";
import DocumentDirectoriesBlock from "./Blocks/DocumentDirectoriesBlock";
import ButtonBlock from "./Blocks/ButtonBlock";
import FilesBlock from "./Blocks/FilesBlock";
import BannerBlock from "./Blocks/BannerBlock";
import FaqBlock from "./Blocks/FaqBlock";
import NewsSubscribesBlock from "./Blocks/NewsSubscribesBlock";
import ProductsSliderBlock from "./Blocks/ProductsSliderBlock";
import ImageWithTextCheckerboardBlock from "./Blocks/ImageWithTextCheckerboardBlock";
import SharingBlock from "./Blocks/SharingBlock";
import OtherNewsBlock from "./Blocks/OtherNewsBlock";

export type BlockProps<Value> = {
    index: number;
    value: Value;
};

export type Props = {
    blocks: ContentBlockModel[] | undefined;
};

export default function ContentBlocks(props: Props) {
    const [blocks, setBlocks] = useState<ContentBlockModel[]>(
        props.blocks || [],
    );
    const [showModal, setShowModal] = useState<boolean>(false);

    const addBlock = (block: ContentBlockModel): void =>
        setBlocks([...blocks, block]);

    const removeBlock = (block: ContentBlockModel): void =>
        setBlocks(blocks.filter((item) => item.id !== block.id));

    const renderBlocks = (
        { type, value }: ContentBlockModel,
        index: number,
    ): ReactNode => {
        const props: BlockProps<any> = {
            index,
            value,
        };

        switch (type) {
            case ContentBlockType.Image:
                return <ImageBlock {...props} />;

            case ContentBlockType.Text:
                return <TextBlock {...props} />;

            case ContentBlockType.PhotoWithTextSlider:
                return <PhotoWithTextSliderBlock {...props} />;

            case ContentBlockType.Video:
                return <VideoBlock {...props} />;

            case ContentBlockType.TitleWithText:
                return <HeadingWithTextBlock {...props} />;

            case ContentBlockType.TitleWithOrderedList:
            case ContentBlockType.TitleWithUnorderedList:
                return <HeadingWithListBlock {...props} />;

            case ContentBlockType.BGColorText:
                return <BGColorTextBlock {...props} />;

            case ContentBlockType.ImageWithTextCheckerboard:
                return <ImageWithTextCheckerboardBlock {...props} />;

            case ContentBlockType.Leaders:
                return <LeadersBlock {...props} />;

            case ContentBlockType.ExpertOpinions:
                return <ExpertOpinionBlock {...props} />;

            case ContentBlockType.Directories:
                return <DocumentDirectoriesBlock {...props} />;

            case ContentBlockType.Button:
                return <ButtonBlock {...props} />;

            case ContentBlockType.ImagesSlider:
                return <PhotoSliderBlock {...props} />;

            case ContentBlockType.Files:
                return <FilesBlock {...props} />;

            case ContentBlockType.Banner:
                return <BannerBlock {...props} />;

            case ContentBlockType.Faq:
                return <FaqBlock {...props} />;

            case ContentBlockType.NewsSubscribes:
                return <NewsSubscribesBlock {...props} />;

            case ContentBlockType.ProductsSlider:
                return <ProductsSliderBlock {...props} />;

            case ContentBlockType.OtherNews:
                return <OtherNewsBlock {...props} />;

            case ContentBlockType.Sharing:
                return <SharingBlock {...props} />;

            default:
                return null;
        }
    };

    return (
        <>
            <Sortable list={blocks} setList={setBlocks}>
                {blocks.map((block: ContentBlockModel, index: number) => (
                    <div
                        key={block.id || `new-block-${index}`}
                        className="rounded border p-4 my-8 mb-5"
                    >
                        <input
                            type="hidden"
                            name={`blocks[${index}][id]`}
                            value={block.id}
                        />

                        <input
                            type="hidden"
                            name={`blocks[${index}][type]`}
                            value={block.type}
                        />

                        <h4 className="mb-5">{TYPES[block.type]}</h4>

                        {renderBlocks(block, index)}

                        <div className="mt-5">
                            <a
                                className="card-link text-danger"
                                onClick={() => removeBlock(block)}
                            >
                                Удалить блок
                            </a>
                        </div>
                    </div>
                ))}
            </Sortable>

            <button
                type="button"
                className="btn btn-success"
                onClick={(): void => setShowModal(true)}
            >
                Добавить блок
            </button>

            <AddBlockModal
                showModal={showModal}
                closeModal={(): void => setShowModal(false)}
                onSubmit={addBlock}
            />
        </>
    );
}

import { createContext, type JSX, useContext } from "react";
import { Language } from "@admin/domain/languages/Languages";

type LanguageContextProps = {
    currentLanguage: Language;
    availableLanguages: Language[];
};

const Context = createContext<LanguageContextProps>(undefined as any);

export function useLanguageContext() {
    return useContext(Context);
}

export default function LanguagesContext({
    children,
}: {
    children: JSX.Element;
}) {
    const currentLanguage = window.CMS.currentLanguage;
    const availableLanguages = window.CMS.availableLanguages;

    if (!currentLanguage || !availableLanguages) {
        throw Error(`availableLanguages or currentLanguage not set`);
    }

    return (
        <Context.Provider value={{ currentLanguage, availableLanguages }}>
            {children}
        </Context.Provider>
    );
}

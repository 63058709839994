import React, { type ReactNode } from "react";

import type { ArticleContentBlockModel } from "@admin/domain/articles/models/ArticleContentBlockModel";
import { ArticleBlockType } from "@admin/domain/articles/enums/ArticleBlockType";
import ArticleSubscribesBlock from "./Blocks/ArticleSubscribesBlock";
import ColorTabsBlock from "./Blocks/ColorTabsBlock";
import DropDownElementsBlock from "./Blocks/DropDownElementsBlock";
import FaqBlock from "./Blocks/FaqBlock";
import IconsBlock from "./Blocks/IconsBlock";
import ImageWithTextCheckerboardBlock from "./Blocks/ImageWithTextCheckerboardBlock";
import ItemsListBlock from "./Blocks/ItemsListBlock";
import NotificationsBlock from "./Blocks/NotificationsBlock";
import ProductsSliderBlock from "./Blocks/ProductsSliderBlock";
import ProgramRulesBlock from "./Blocks/ProgramRulesBlock";
import ScoreWithLinksBlock from "./Blocks/ScoreWithLinksBlock";
import TitleWithTextImageBlock from "./Blocks/TitleWithTextImageBlock";

export const OPTIONS = {
    [ArticleBlockType.TitleWithTextImage]: `С заголовком, текстом и изображением`,
    [ArticleBlockType.Faq]: `Часто задаваемые вопросы`,
    [ArticleBlockType.HorizontalImageWithTextCheckerboard]: `С горизонтальными изображениями и текстом в шахматном порядке`,
    [ArticleBlockType.VerticalImageWithTextCheckerboard]: `С вертикальными изображениями и текстом в шахматном порядке`,
    [ArticleBlockType.OrderedList]: `С нумерованным списком`,
    [ArticleBlockType.UnorderedList]: `С маркированным списком`,
    [ArticleBlockType.Notifications]: `С уведомлениями`,
    [ArticleBlockType.ColorTabs]: `С цветными плашками`,
    [ArticleBlockType.Icons]: `С иконками`,
    [ArticleBlockType.ProductsSlider]: `С плашками товаров`,
    [ArticleBlockType.DropDownElements]: `С раскрывающимися элементами`,
    [ArticleBlockType.ScoreWithLinks]: `С кнопками лайка, дизлайка и ссылками на социальные сети`,
    [ArticleBlockType.ProgramRules]: `С правилами приёма программы`,
    [ArticleBlockType.ArticleSubscribes]: `Подписки на статьи`,
} as const;

export type Props = {
    block: ArticleContentBlockModel;
    index: number;
    onDelete: (blockId: number | string) => void;
};

export default function Block({ block, index, onDelete }: Props) {
    const renderBlock = (
        block: ArticleContentBlockModel,
        index: number,
    ): ReactNode => {
        const props: {
            block: any;
            index: number;
            onDelete: (id: number) => void;
        } = { block, index, onDelete: onDelete };

        switch (block.type) {
            case ArticleBlockType.TitleWithTextImage:
                return <TitleWithTextImageBlock {...props} />;

            case ArticleBlockType.Faq:
                return <FaqBlock {...props} />;

            case ArticleBlockType.HorizontalImageWithTextCheckerboard:
            case ArticleBlockType.VerticalImageWithTextCheckerboard:
                return <ImageWithTextCheckerboardBlock {...props} />;

            case ArticleBlockType.OrderedList:
            case ArticleBlockType.UnorderedList:
                return <ItemsListBlock {...props} />;

            case ArticleBlockType.Notifications:
                return <NotificationsBlock {...props} />;

            case ArticleBlockType.ColorTabs:
                return <ColorTabsBlock {...props} />;

            case ArticleBlockType.Icons:
                return <IconsBlock {...props} />;

            case ArticleBlockType.ProductsSlider:
                return <ProductsSliderBlock {...props} />;

            case ArticleBlockType.DropDownElements:
                return <DropDownElementsBlock {...props} />;

            case ArticleBlockType.ScoreWithLinks:
                return <ScoreWithLinksBlock {...props} />;

            case ArticleBlockType.ProgramRules:
                return <ProgramRulesBlock {...props} />;

            case ArticleBlockType.ArticleSubscribes:
                return <ArticleSubscribesBlock {...props} />;

            default:
                return null;
        }
    };

    return (
        <div
            className="rounded border p-10 my-5"
            key={block.id || `new-block-${index}`}
        >
            <div className="mb-10">
                <h4 className="mb-5">
                    Блок "{OPTIONS[block.type] || `Неизвестного типа`}"
                </h4>

                <input
                    type="hidden"
                    name={`blocks[${index}][id]`}
                    value={block.id}
                />

                <input
                    type="hidden"
                    name={`blocks[${index}][type]`}
                    value={block.type}
                />

                {renderBlock(block, index)}

                <div>
                    <a
                        className="card-link text-danger"
                        onClick={() => {
                            onDelete(block.id);
                        }}
                    >
                        Удалить блок
                    </a>
                </div>
            </div>
        </div>
    );
}

import React from "react";

import type { FileModel } from "@admin/domain/files/model/FileModel";
import type { LanguageKeyObject } from "@admin/domain/languages/Languages";
import Input from "@admin/components/Input";
import ImageInput from "@admin/components/ImageInput";

type Props = {
    index: LanguageKeyObject;
    file: FileModel | null;
    url: string;
    remove: () => void;
};

export default function AdditionalMaterialsBannerBlockItem({
    index,
    file,
    url,
    remove,
}: Props) {
    return (
        <div className="col card border m-2 p-8">
            <div>
                <ImageInput
                    label="Изображение"
                    name={`content[banners][${index}][file_id]`}
                    defaultValue={file}
                    width="150px"
                    thumbnailHeight="150px"
                    thumbnailWidth="150px"
                />

                <Input
                    label="Ссылка"
                    defaultValue={url}
                    name={`content[banners][${index}][url]`}
                />
            </div>

            <div className="mt-8">
                <a className="card-link text-danger" onClick={remove}>
                    Удалить баннер
                </a>
            </div>
        </div>
    );
}

import React from "react";

import type { HeadingModel } from "@admin/domain/content/models/ContentBlockValueModel";
import MultiLanguages from "@admin/components/MultiLanguage";
import Select, { type Option } from "@admin/components/Select";

const OPTIONS: ReadonlyArray<Option> = [
    { value: `h2`, label: `h2` },
    { value: `h3`, label: `h3` },
    { value: `h4`, label: `h4` },
    { value: `h5`, label: `h5` },
    { value: `h6`, label: `h6` },
] as const;

type Props = HeadingModel & {
    name: string;
};

export default function HeadingInput(props: Props) {
    return (
        <>
            <Select
                label="Уровень заголовка"
                name={`${props.name}[value]`}
                options={OPTIONS}
                defaultValue={props.value}
                prependEmptyOption={false}
            />

            <MultiLanguages
                languageElement={(language) => (
                    <div className="mb-5">
                        <label className="form-label">Заголовок</label>
                        <input
                            className="form-control"
                            name={`${props.name}[title][${language}]`}
                            defaultValue={props.title[language] || ``}
                        />
                    </div>
                )}
            />
        </>
    );
}

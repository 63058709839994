import React from "react";
import { useDataContext } from "@admin/components/DataLoader";
import { LogData, LogItem } from "@admin/domain/logs/dto/LogItem";
import ReactJson from "react-json-view";
import classNames from "classnames";
import { addQueryToUrl } from "@admin/utils/url";

export default function Table() {
    const {
        data: { paginator, query },
    } = useDataContext<LogData>();

    return (
        <div className="card">
            <div className="card-body">
                <table className="table table-striped gy-4 gs-4 align-middle">
                    <thead>
                        <tr className="fw-bold fs-6 text-gray-800 border-bottom border-gray-200">
                            <th>
                                ID
                                <a
                                    href={addQueryToUrl({
                                        id_desc: query.id_desc === false,
                                        created_at_desc: null,
                                    })}
                                >
                                    <i
                                        className={classNames(
                                            `ms-4 fas cursor-pointer`,
                                            {
                                                "text-primary":
                                                    query.id_desc !== null,
                                                "fa-arrow-up": !query.id_desc,
                                                "fa-arrow-down": query.id_desc,
                                            },
                                        )}
                                    />
                                </a>
                            </th>
                            <th>Тип события</th>
                            <th>Автор события</th>
                            <th>
                                Дата и время события
                                <a
                                    href={addQueryToUrl({
                                        id_desc: null,
                                        created_at_desc: !query.created_at_desc,
                                    })}
                                >
                                    <i
                                        className={classNames(
                                            `ms-4 fas cursor-pointer`,
                                            {
                                                "text-primary":
                                                    query.created_at_desc !==
                                                    null,
                                                "fa-arrow-up":
                                                    !query.created_at_desc,
                                                "fa-arrow-down":
                                                    query.created_at_desc,
                                            },
                                        )}
                                    />
                                </a>
                            </th>
                            <th style={{ minWidth: `800px` }}>Значение</th>
                        </tr>
                    </thead>
                    <tbody>
                        {paginator.items.map((log: LogItem) => (
                            <tr key={log.id}>
                                <td>{log.id}</td>
                                <td>{log.action}</td>
                                <td>{log.initiator_name}</td>
                                <td>{log.created_at}</td>
                                <td>
                                    {log.context ? (
                                        <ReactJson
                                            src={log.context}
                                            displayDataTypes={false}
                                            displayObjectSize={false}
                                            collapseStringsAfterLength={100}
                                            enableClipboard={false}
                                            name={false}
                                            collapsed={true}
                                        />
                                    ) : null}
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
}

import { DateTime } from "luxon";

export const formatFromISO = (
    value: string | null | undefined,
): string | undefined => {
    if (!value) {
        return undefined;
    }

    const formattedDate = DateTime.fromISO(value)?.toFormat(
        `yyyy-LL-dd'T'hh:mm`,
    ) as string;

    if (formattedDate === `Invalid DateTime`) {
        return DateTime.fromSQL(value)?.toFormat(
            `yyyy-LL-dd'T'hh:mm`,
        ) as string;
    }

    return formattedDate;
};

export const now = (): string => {
    const date = new Date();
    return getFormattedDate(date);
};

export const getFormattedDate = (date: Date | string): string => {
    if (!(date instanceof Date)) {
        date = new Date(date);
    }

    return `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, `0`)}-${String(date.getDate()).padStart(2, `0`)}T${String(date.getHours()).padStart(2, `0`)}:${String(date.getMinutes()).padStart(2, `0`)}`;
};

import { ContentBlockType } from "@admin/domain/content/enums/ContentBlockType";

const TYPES = {
    [ContentBlockType.Image]: `Изображение`,
    [ContentBlockType.Text]: `Текст без заголовка`,
    [ContentBlockType.PhotoWithTextSlider]: `Слайдер фото c текстом`,
    [ContentBlockType.Video]: `Видео`,
    [ContentBlockType.TitleWithText]: `Заголовок определённого уровня и текст`,
    [ContentBlockType.TitleWithOrderedList]: `Заголовок определённого уровня и нумерованный список`,
    [ContentBlockType.TitleWithUnorderedList]: `Заголовок определённого уровня и маркированный список`,
    [ContentBlockType.BGColorText]: `Текст на фоне серого цвета`,
    [ContentBlockType.ImageWithTextCheckerboard]: `Изображения с текстом в шахматном порядке`,
    [ContentBlockType.Leaders]: `"Лидеры месяца"`,
    [ContentBlockType.ExpertOpinions]: `Мнение эксперта`,
    [ContentBlockType.Directories]: `Папки с документами`,
    [ContentBlockType.Button]: `Кнопка для перехода`,
    [ContentBlockType.ImagesSlider]: `Слайдер изображений`,
    [ContentBlockType.Files]: `Файлы`,
    [ContentBlockType.Banner]: `Баннер`,
    [ContentBlockType.Faq]: `"Часто задаваемые вопросы"`,
    [ContentBlockType.ProductsSlider]: `Слайдер товаров`,
    [ContentBlockType.OtherNews]: `"Другие новости"`,
    [ContentBlockType.Sharing]: `Поделиться в соц. сетях`,
} as const;

export default TYPES;

import React from "react";
import debounce from "lodash-es/debounce";
import AsyncSelect from "react-select/async";
import { useIsInvalid } from "../FormErrorsContext";
import { SelectOption } from "@admin/utils/types";
import { searchUser as searchUserRequest } from "@admin/domain/users/api";
import { type UserModel } from "@admin/domain/users/models/UserModel";

type Props = {
    inputName?: string;
    userId?: number;
    userName?: string;
    onSelectUser?: (user: UserModel) => void;
    label?: string;
};

export const UsersSearch: React.FC<Props> = ({
    userId,
    userName,
    inputName,
    onSelectUser = () => null,
    label,
}) => {
    const isInvalid = useIsInvalid(inputName);
    const [users, setUsers] = React.useState<Map<number, UserModel>>(new Map());

    const searchUser = debounce(
        (
            query: string,
            setSelectOptions: (options: SelectOption[]) => void,
        ) => {
            searchUserRequest(query)
                .then((users: UserModel[]): void => {
                    setUsers(
                        new Map(
                            users.map((user: UserModel) => [user.id, user]),
                        ),
                    );

                    setSelectOptions(
                        users.map((user: UserModel) => ({
                            value: user.id,
                            label: `${user.full_name} #${user.personal_number}`,
                        })),
                    );
                })
                .catch((): void => {
                    setUsers(new Map());
                    setSelectOptions([]);
                });
        },
        500,
    );

    return (
        <div className="mb-5">
            <label className="form-label">
                {label || `Поиск дистрибьютора`}
            </label>

            <AsyncSelect
                classNames={{
                    control: (): string =>
                        `form-control p-0` + (isInvalid ? ` is-invalid` : ``),
                }}
                placeholder="Поиск дистрибьютера"
                loadOptions={searchUser}
                defaultValue={
                    userId
                        ? {
                              label: userName,
                              value: userId,
                          }
                        : undefined
                }
                name={inputName}
                isClearable
                required
                onChange={(option): void =>
                    onSelectUser(users.get(option?.value || 0) as UserModel)
                }
            />
        </div>
    );
};

export default UsersSearch;

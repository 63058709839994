import React from "react";
import Editor from "@admin/components/Editor";
import { updateFooterBlocks } from "@admin/domain/footer/api";
import FooterBlocksEditor from "@admin/pages/FooterEditor/FooterBlocksEditor";

export default function FooterEditor() {
    const footerBlocks = window.CMS.footerBlocks || null;
    const execute = async (body: FormData) => {
        await updateFooterBlocks(body);
    };

    return (
        <Editor backUrl="/admin/footer" onSubmit={execute}>
            <FooterBlocksEditor blocks={footerBlocks} />
        </Editor>
    );
}

import React, { useEffect } from "react";
import { type EditOrderData } from "@admin/domain/orders/dto/EditOrderData";
import { type ProductForOrderData } from "@admin/domain/orders/dto/ProductForOrderData";
import { OrderType } from "@admin/domain/orders/enums/OrderType";
import Input from "@admin/components/Input";
import {
    getProductsForOrder,
    updateOrderProducts,
} from "@admin/domain/orders/api";
import { useLoaderContext } from "@admin/components/LoaderContext";
import debounce from "lodash-es/debounce";
import { chooseDeclension } from "@admin/utils/formatters";
import BaseModal from "@admin/components/Modal";
import { useOrderHooks } from "@admin/pages/OrderEditor/hooks";

type Props = {
    order: EditOrderData;
    closeModal: () => void;
};

export default function AddProductsModal({ order, closeModal }: Props) {
    const [products, setProducts] = React.useState<ProductForOrderData[]>([]);
    const [query, setQuery] = React.useState<string>(``);
    const [selectedProducts, setSelectedProducts] = React.useState<
        Map<string, number>
    >(new Map());
    const selectedProductsCount = Array.from(selectedProducts).reduce(
        (result: number, [, count]: [string, number]): number => result + count,
        0,
    );

    const { isLoading, request } = useLoaderContext();

    const { actualizeOrder } = useOrderHooks();

    const getProducts = async (query = ``): Promise<void> => {
        request(async (): Promise<void> => {
            const loadedProducts = await getProductsForOrder(order.id, query);

            setProducts(loadedProducts);
        });
    };

    useEffect((): void => {
        // noinspection JSIgnoredPromiseFromCall
        getProducts();

        return;
    }, []);

    const searchProducts = debounce(
        (e: React.ChangeEvent<HTMLInputElement>): Promise<void> => {
            const newQuery = e.target.value;
            setQuery(newQuery);

            return getProducts(newQuery);
        },
        500,
    );

    const addProduct = (
        e: React.ChangeEvent<HTMLInputElement>,
        product: ProductForOrderData,
    ): void => {
        setSelectedProducts(
            new Map([...selectedProducts, [product.id, +e.target.value]]),
        );
    };

    const addProductsToOrder = async (): Promise<void> => {
        request(async (): Promise<void> => {
            await updateOrderProducts(
                order.id,
                Array.from(selectedProducts, ([id, quantity]) => ({
                    id,
                    quantity,
                })),
            );

            await actualizeOrder();
            closeModal();
        });
    };

    return (
        <BaseModal
            size="lg"
            closeModal={closeModal}
            title="Добавление товара"
            body={
                <>
                    {order.type === OrderType.Auto && (
                        <p>Акционные товары не доступны для автозаказа</p>
                    )}
                    <Input
                        label="Поиск товаров"
                        placeholder="Введите не менее двух символов для поиска"
                        onChange={searchProducts}
                        readOnly={isLoading}
                    />
                    {products.length > 0 && (
                        <div
                            className="table-responsive"
                            style={{ height: `350px` }}
                        >
                            <table className="table table-striped gy-4 gs-4 overflow-auto w-100 mb-5">
                                <thead>
                                    <tr>
                                        <td scope="col">Товар</td>
                                        <td scope="col">Цена</td>
                                        <td scope="col">Баллы</td>
                                        <td
                                            scope="col"
                                            style={{ width: `80px` }}
                                        >
                                            Кол-во
                                        </td>
                                    </tr>
                                </thead>
                                <tbody>
                                    {products.map(
                                        (product: ProductForOrderData) => (
                                            <tr key={product.id}>
                                                <td>
                                                    <a
                                                        href={`/admin/products/${product.id}`}
                                                        target="_blank"
                                                    >
                                                        {product.title}
                                                    </a>
                                                </td>
                                                <td>
                                                    {product.price / 100}
                                                    {` `}
                                                    {order.currency_symbol}
                                                </td>
                                                <td>{product.points_reward}</td>
                                                <td>
                                                    <input
                                                        className="form-control"
                                                        type="number"
                                                        min={0}
                                                        value={
                                                            selectedProducts.get(
                                                                product.id,
                                                            ) || ``
                                                        }
                                                        onChange={(e) =>
                                                            addProduct(
                                                                e,
                                                                product,
                                                            )
                                                        }
                                                        disabled={isLoading}
                                                    />
                                                </td>
                                            </tr>
                                        ),
                                    )}
                                </tbody>
                            </table>
                        </div>
                    )}
                    {query.length > 2 && products.length === 0 && (
                        <p>Товары не найдены, измените параметры поиска</p>
                    )}
                </>
            }
            footer={
                <>
                    <button
                        className="btn btn-secondary"
                        onClick={closeModal}
                        disabled={isLoading}
                    >
                        Закрыть
                    </button>

                    {selectedProductsCount > 0 && (
                        <button
                            className="btn btn-primary"
                            onClick={addProductsToOrder}
                            disabled={isLoading}
                        >
                            Добавить {selectedProductsCount}
                            {` `}
                            {chooseDeclension(
                                selectedProductsCount,
                                `товар`,
                                `товара`,
                                `товаров`,
                            )}
                        </button>
                    )}
                </>
            }
        />
    );
}

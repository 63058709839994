import React from "react";
import { useOrderContext } from "@admin/pages/OrderEditor/OrderContext";

export default function CreatorInfo() {
    const { order } = useOrderContext();

    return (
        <div className="rounded border p-6 mb-5">
            <div className="fw-bolder">Создатель заказа:</div>
            <div className="ms-5">
                <div>
                    <span className="fw-bolder">ФИО: </span>
                    <a
                        href={`/admin/distributors/${order.creator.id}`}
                        target="_blank"
                    >
                        {order.creator.full_name}
                    </a>
                </div>
                <div>
                    <span className="fw-bolder">Почта: </span>
                    {order.creator.email}
                </div>
                <div>
                    <span className="fw-bolder">Личный номер: </span>
                    {order.creator.personal_number}
                </div>
                <div>
                    <span className="fw-bolder">
                        Статус по маркетинг-плану:{` `}
                    </span>
                    {order.target_marketing_plan_status}
                </div>
                <a
                    className="d-block"
                    href={`/admin/orders?filters[search]=${order.creator.personal_number}`}
                    target="_blank"
                >
                    Посмотреть все заказы
                </a>
            </div>
        </div>
    );
}

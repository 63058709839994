import React from "react";
import Input from "@admin/components/Input";
import { EditOrderData } from "@admin/domain/orders/dto/EditOrderData";
import formatMoney from "@admin/domain/orders/formatMoney";

type Props = {
    order: EditOrderData;
};

export default function Accounting({ order }: Props) {
    return (
        <>
            <h4 className="mb-5">Синхронизация с 1С</h4>
            <Input
                value={order.id_from_1c}
                label="GUID заказа в 1C"
                className={{ block: `col-4 mb-5` }}
                disabled
            />
            <Input
                value={order.payment_external_id}
                label="GUID платежа по заказу в 1C"
                className={{ block: `col-4 mb-5` }}
                disabled
            />
            <Input
                value={
                    order.is_parent || !order.id_from_1c
                        ? ``
                        : formatMoney(order, `total_amount`)
                }
                label="Сумма платежа"
                className={{ block: `col-4 mb-5` }}
                disabled
            />
            <Input
                value={order.paid_at}
                label="Дата платежа"
                className={{ block: `col-4 mb-5` }}
                disabled
            />
        </>
    );
}

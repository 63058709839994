import React from "react";
import debounce from "lodash-es/debounce";
import AsyncSelect from "react-select/async";

import {
    type FoundCustomPage,
    type FoundCustomPagesPayload,
    searchCustomPages,
} from "@admin/domain/customPages/api";
import { useIsInvalid } from "../FormErrorsContext";
import { SelectOption } from "@admin/utils/types";

type Props = {
    inputName: string;
    label?: string;
    pageId?: number;
    pageTitle?: string;
    onChange?: (value: number | undefined, label: string | undefined) => void;
    required: boolean;
};

export const CustomPagesSearch: React.FC<Props> = ({
    pageId,
    pageTitle,
    inputName,
    label,
    onChange = () => null,
    required = false,
}) => {
    const isInvalid = useIsInvalid(inputName);

    const debouncedSearch = debounce(
        (
            query: string,
            setOptionsCallback: (options: ReadonlyArray<SelectOption>) => void,
        ): void => {
            if (query.length < 2) {
                return;
            }

            searchCustomPages(query).then(
                ({ customPages }: FoundCustomPagesPayload): void =>
                    setOptionsCallback(
                        customPages.map(
                            (customPage: FoundCustomPage): SelectOption => ({
                                value: customPage.id,
                                label: customPage.title,
                            }),
                        ),
                    ),
            );
        },
        500,
    );

    return (
        <div className="mb-5">
            {label && <label className="form-label">{label}</label>}

            <AsyncSelect
                classNames={{
                    control: () =>
                        `form-control p-0` + (isInvalid ? ` is-invalid` : ``),
                }}
                placeholder="Поиск по заголовку страницы"
                loadOptions={debouncedSearch}
                defaultValue={
                    pageId
                        ? {
                              label: pageTitle,
                              value: pageId,
                          }
                        : undefined
                }
                name={inputName}
                isClearable
                required={required}
                onChange={(option): void =>
                    onChange(option?.value as number, option?.label)
                }
            />
        </div>
    );
};

export default CustomPagesSearch;

import { request } from "../api";
import { AdminModel } from "@admin/domain/admins/models/AdminModel";

export type FoundAdminsPayload = {
    admins: ReadonlyArray<FoundAdmin>;
};

export type FoundAdmin = {
    id: AdminModel[`id`];
    name: AdminModel[`name`];
    email: AdminModel[`email`];
};

export const searchAdmins = async (
    query: string,
): Promise<FoundAdminsPayload> => {
    return await request({
        url: `/admin/admins/search`,
        query: { query },
    });
};

import React, { useState } from "react";
import Spinner from "react-bootstrap/Spinner";

import type { FileModel } from "@admin/domain/files/model/FileModel";
import { makePath } from "@admin/utils/makePath";
import { upload } from "@admin/domain/products/api";

type FileItem = FileModel | null;

type Props = {
    files: Array<FileModel | null>;
    fileInputFunction: (fileIndex: number) => string;
};

export default function FilesList({
    files: initFiles,
    fileInputFunction,
}: Props) {
    const [files, setFiles] = useState<Array<FileItem>>(initFiles);
    const [isUploading, setIsUploading] = useState<boolean>(false);

    async function loadFile(
        file: File | null | undefined,
        index: number,
    ): Promise<void> {
        if (!file) {
            alert(`Необходимо выбрать файл!`);
            return;
        }

        setIsUploading(true);

        const uploadedFile = await upload(file);

        setFiles(
            files.map((prevFile, prevIndex) => {
                if (prevIndex === index) {
                    return uploadedFile;
                }

                return prevFile;
            }),
        );

        setIsUploading(false);
    }

    const addFile = (): void => {
        setFiles([...files, null]);
    };

    const deleteFile = (index: number): void => {
        setFiles(files.filter((_prevFile, prevIndex) => prevIndex !== index));
    };

    const resetFile = (index: number): void =>
        setFiles(
            files.map((prevFile, prevIndex) => {
                if (prevIndex === index) {
                    return null;
                }

                return prevFile;
            }),
        );

    return (
        <div className="position-relative">
            {files.map((file, index: number) => {
                return file ? (
                    <div
                        key={file?.id || index}
                        className="rounded border p-4 mb-5"
                    >
                        <div className="position-relative">
                            <input
                                type="hidden"
                                name={fileInputFunction(index)}
                                defaultValue={file.id}
                            />

                            <div>
                                <span className="fw-bolder">
                                    Название файла:
                                </span>
                                {` `}
                                {file.name}
                            </div>

                            <div>
                                <span className="fw-bolder">Формат файла:</span>
                                {` `}
                                {file.mime}
                            </div>

                            <a
                                href={makePath(file.path)}
                                target="_blank"
                                className="fw-bolder"
                            >
                                Скачать файл
                            </a>

                            <div className="position-absolute top-0 end-0">
                                <button
                                    type="button"
                                    title="Удалить"
                                    className="btn btn-icon btn-icon-danger"
                                    onClick={() => resetFile(index)}
                                >
                                    <i className="fa fa-trash" />
                                </button>
                            </div>
                        </div>
                    </div>
                ) : (
                    <div key={`new-${index}`} className="input-group mb-5">
                        <input
                            type="file"
                            className="form-control"
                            name="file"
                            onChange={(event) =>
                                loadFile(
                                    event.target.files?.item(0) as File,
                                    index,
                                )
                            }
                            disabled={isUploading}
                        />

                        <button
                            type="button"
                            className="btn btn-outline btn-outline-danger"
                            onClick={() => deleteFile(index)}
                        >
                            Удалить
                        </button>
                    </div>
                );
            })}

            {isUploading && (
                <div className="d-flex align-items-center justify-content-center position-absolute start-0 top-0 w-100 h-100 bg-info bg-opacity-10">
                    <Spinner variant="info" />
                </div>
            )}

            <div className="mb-5 me-5 d-flex">
                <button
                    type="button"
                    className="btn btn-success"
                    onClick={addFile}
                    disabled={isUploading}
                >
                    Добавить файл
                </button>
            </div>
        </div>
    );
}

import React, { type ChangeEvent, useCallback, useState } from "react";
import Spinner from "react-bootstrap/Spinner";

import type { FileModel } from "@admin/domain/files/model/FileModel";
import { makePath } from "@admin/utils/makePath";
import { upload } from "@admin/domain/products/api";

type Props = {
    name: string;
    label: string;
    defaultValue: FileModel | null | undefined;
    accept?: string;
    onChange?: (file: FileModel | null) => void;
};

export default function FileInput({
    name,
    label,
    defaultValue,
    accept,
    onChange,
}: Props) {
    const [file, setFile] = useState<FileModel | null>(defaultValue || null);
    const [isUploading, setIsUploading] = useState<boolean>(false);

    async function handleSelectedFile(event: ChangeEvent<HTMLInputElement>) {
        const pendingFile = event.target.files?.item(0);

        if (pendingFile) {
            setIsUploading(true);

            const file = await upload(pendingFile);

            setFile(file);

            onChange?.(file);

            setIsUploading(false);
        }
    }

    const remove = useCallback(() => {
        if (confirm(`Вы действительно хотите удалить файл?`)) {
            setFile(null);
            onChange?.(null);
        }
    }, []);

    if (file) {
        return (
            <div className="border mb-5 my-8 p-4 rounded">
                <div className="position-relative">
                    <input type="hidden" name={name} defaultValue={file.id} />

                    <div>
                        <span className="fw-bolder">Название файла:</span>
                        {` `}
                        {file.name}
                    </div>

                    <div>
                        <span className="fw-bolder">Формат файла:</span>
                        {` `}
                        {file.mime}
                    </div>

                    <a
                        href={makePath(file.path)}
                        target="_blank"
                        className="fw-bolder"
                    >
                        Скачать файл
                    </a>

                    <div className="position-absolute top-0 end-0">
                        <i
                            title="Удалить"
                            className="fa fa-lg fa-trash text-danger cursor-pointer"
                            onClick={remove}
                        />
                    </div>
                </div>
            </div>
        );
    }

    return (
        <div className="mb-5">
            <label className="form-label">{label}</label>

            <input
                type="file"
                className="form-control"
                name="file"
                accept={accept}
                onChange={handleSelectedFile}
            />

            {isUploading && (
                <div className="align-items-center bg-info bg-opacity-10 d-flex h-100 justify-content-center position-absolute start-0 top-0 w-100">
                    <Spinner variant="info" />
                </div>
            )}
        </div>
    );
}

import React, { useState } from "react";
import { AboutPageResearchBannerModel } from "@admin/domain/uniquePages/models/AboutPageModel";
import {
    initLanguageKeyObject,
    Language,
} from "@admin/domain/languages/Languages";
import Modal from "react-bootstrap/Modal";
import { Alert } from "react-bootstrap";
import Input from "@admin/components/Input";
import { useLanguageContext } from "@admin/components/LanguagesContext";
import MultiLanguages from "@admin/components/MultiLanguage";
import { v4 as uuid } from "uuid";
import ImageInput from "@admin/components/ImageInput";

type Props = {
    showModal: boolean;
    closeModal: () => void;
    onSubmit: (data: AboutPageResearchBannerModel) => void;
    editableBanner?: AboutPageResearchBannerModel;
};

export default function AboutPageResearchModal(props: Props) {
    const languageContext = useLanguageContext();
    const [banner, setBanner] = useState<AboutPageResearchBannerModel>(
        props.editableBanner || {
            id: uuid(),
            title: initLanguageKeyObject(),
            url: ``,
            file: null,
        },
    );

    const [errors, setErrors] = useState<string[]>([]);
    const submit = () => {
        const newErrors = [];
        if (!banner.file) newErrors.push(`Изображение обязательно`);
        for (const language of languageContext.availableLanguages) {
            // @ts-ignore
            if (!banner.title[language])
                newErrors.push(
                    `Поле "${language} Название исследования" обязательно для заполнения`,
                );
        }
        if (!banner.url)
            newErrors.push(`Поле "Ссылка" обязательно для заполнения`);
        if (newErrors.length > 0) {
            setErrors(newErrors);
            return;
        }

        props.onSubmit(banner as AboutPageResearchBannerModel);
        props.closeModal();
    };

    return (
        <Modal centered show={props.showModal} onHide={props.closeModal}>
            <Modal.Header>
                <Modal.Title>
                    {props.editableBanner ? `Обновление` : `Добавление`}
                    {` `}
                    исследования
                </Modal.Title>
            </Modal.Header>

            <Modal.Body>
                {errors.length > 0 && (
                    <Alert variant="danger">
                        {errors.map((err, index) => (
                            <div key={index}>{err}</div>
                        ))}
                    </Alert>
                )}

                <div className="d-flex align-items-center justify-content-center">
                    <ImageInput
                        label="Изображение"
                        name=""
                        onChange={(file) => setBanner({ ...banner, file })}
                        defaultValue={banner.file}
                        width="300px"
                        height="250px"
                    />
                </div>

                <MultiLanguages
                    languageElement={(language: Language) => (
                        <Input
                            label="Название исследования"
                            onChange={(event) => {
                                setBanner({
                                    ...banner,
                                    title: {
                                        ...banner.title,
                                        [language]: event.target.value,
                                    },
                                });
                            }}
                            value={banner.title[language]}
                        />
                    )}
                />

                <Input
                    label="Ссылка"
                    onChange={(event) => {
                        setBanner({
                            ...banner,
                            url: event.target.value,
                        });
                    }}
                    value={banner.url}
                />
            </Modal.Body>

            <Modal.Footer>
                <button
                    className="btn btn-secondary"
                    type="button"
                    onClick={props.closeModal}
                >
                    Закрыть
                </button>
                <button className="btn btn-primary" onClick={() => submit()}>
                    {props.editableBanner ? `Обновить` : `Сохранить`}
                </button>
            </Modal.Footer>
        </Modal>
    );
}

import Modal from "react-bootstrap/Modal";
import { Alert } from "react-bootstrap";
import React, { useState } from "react";
import Input from "@admin/components/Input";
import { AboutPageGroupCompaniesBannerModel } from "@admin/domain/uniquePages/models/AboutPageModel";
import {
    initLanguageKeyObject,
    Language,
} from "@admin/domain/languages/Languages";
import { FileModel } from "@admin/domain/files/model/FileModel";
import { v4 as uuid } from "uuid";
import { useLanguageContext } from "@admin/components/LanguagesContext";
import MultiLanguages from "@admin/components/MultiLanguage";
import ImageInput from "@admin/components/ImageInput";

type Props = {
    showModal: boolean;
    closeModal: () => void;
    onSubmit: (data: AboutPageGroupCompaniesBannerModel) => void;
    editableBanner?: AboutPageGroupCompaniesBannerModel;
};

export default function AboutPageGroupCompaniesModal(props: Props) {
    const languageContext = useLanguageContext();
    const [banner, setBanner] = useState<AboutPageGroupCompaniesBannerModel>(
        props.editableBanner || {
            id: uuid(),
            title: initLanguageKeyObject(),
            sub_title: initLanguageKeyObject(),
            url: ``,
            file: null,
        },
    );

    const [errors, setErrors] = useState<string[]>([]);

    const submit = () => {
        const newErrors: string[] = [];
        if (!banner.file) newErrors.push(`Изображение обязательно`);
        for (const language of languageContext.availableLanguages) {
            if (!banner.title[language])
                newErrors.push(
                    `Поле "${language} Заголовок" обязательно для заполнения`,
                );
            if (!banner.sub_title[language])
                newErrors.push(
                    `Поле "${language} Текст под заголовком" обязательно для заполнения`,
                );
        }
        if (newErrors.length > 0) {
            setErrors(newErrors);
            return;
        }

        props.onSubmit({ ...banner, file: banner.file as FileModel });
        props.closeModal();
    };

    return (
        <Modal centered show={props.showModal} onHide={props.closeModal}>
            <Modal.Header>
                <Modal.Title>
                    {props.editableBanner ? `Обновление` : `Добавление`}
                    {` `}
                    временной отметки
                </Modal.Title>
            </Modal.Header>

            <Modal.Body>
                {errors.length > 0 && (
                    <Alert variant="danger">
                        {errors.map((err, index) => (
                            <div key={index}>{err}</div>
                        ))}
                    </Alert>
                )}

                <div className="d-flex align-items-center justify-content-center">
                    <ImageInput
                        label="Изображение"
                        name=""
                        defaultValue={banner.file}
                        onChange={(file) => setBanner({ ...banner, file })}
                        width="660px"
                        height="475px"
                    />
                </div>

                <MultiLanguages
                    languageElement={(language: Language) => (
                        <>
                            <Input
                                label="Заголовок"
                                onChange={(event) => {
                                    setBanner({
                                        ...banner,
                                        title: {
                                            ...banner.title,
                                            [language]: event.target.value,
                                        },
                                    });
                                }}
                                value={banner.title[language]}
                            />

                            <Input
                                label="Подзаголовок"
                                onChange={(event) => {
                                    setBanner({
                                        ...banner,
                                        sub_title: {
                                            ...banner.sub_title,
                                            [language]: event.target.value,
                                        },
                                    });
                                }}
                                value={banner.sub_title[language]}
                            />
                        </>
                    )}
                />

                <Input
                    label="Ссылка"
                    onChange={(event) => {
                        setBanner({
                            ...banner,
                            url: event.target.value,
                        });
                    }}
                    value={banner.url}
                />
            </Modal.Body>

            <Modal.Footer>
                <button
                    className="btn btn-secondary"
                    type="button"
                    onClick={props.closeModal}
                >
                    Закрыть
                </button>
                <button className="btn btn-primary" onClick={() => submit()}>
                    {props.editableBanner ? `Обновить` : `Сохранить`}
                </button>
            </Modal.Footer>
        </Modal>
    );
}

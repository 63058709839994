import React from "react";

import { type UniquePageProps } from "../index";
import { type BusinessExpansionPageModel } from "@admin/domain/uniquePages/models/BusinessExpansionPageModel";
import MultiLanguages from "@admin/components/MultiLanguage";
import ImageInput from "@admin/components/ImageInput";
import VideoInput from "@admin/components/VideoInput";
import FileInput from "@admin/components/FileInput";

export default function BusinessExpansionPageStoreDeliveryPoint({
    page,
}: UniquePageProps<BusinessExpansionPageModel>) {
    return (
        <div className="mb-10">
            <h4 className="mb-5">Блок "Открытие пункта выдачи"</h4>

            <div>
                <MultiLanguages
                    languageElement={(language) => (
                        <>
                            <div className="mb-5">
                                <label className="form-label">Заголовок</label>
                                <input
                                    className="form-control"
                                    name={`content[delivery_point_block__title][${language}]`}
                                    defaultValue={
                                        page.contentObject
                                            .delivery_point_block__title?.[
                                            language
                                        ] || ``
                                    }
                                />
                            </div>

                            <div className="mb-5">
                                <label className="form-label">Описание</label>
                                <textarea
                                    className="form-control"
                                    name={`content[delivery_point_block__description][${language}]`}
                                    defaultValue={
                                        page.contentObject
                                            .delivery_point_block__description?.[
                                            language
                                        ] || ``
                                    }
                                />
                            </div>
                        </>
                    )}
                />

                <div className="mb-5">
                    <label className="form-label">
                        Ссылка на страницу "Подать заявку"
                    </label>
                    <input
                        className="form-control"
                        name={`content[delivery_point_block__url]`}
                        defaultValue={
                            page.contentObject.delivery_point_block__url
                        }
                    />
                </div>

                <div className="row">
                    <div className="mb-5">
                        <ImageInput
                            label="Изображение"
                            name={`content[delivery_point_block__banner_file][file_id]`}
                            defaultValue={
                                page.contentObject
                                    ?.delivery_point_block__banner_file
                            }
                            width="400px"
                            height="400px"
                        />
                    </div>
                </div>

                <div className="mb-5">
                    <VideoInput
                        name={{
                            file: `content[delivery_point_block__video_file][file_id]`,
                            url: () =>
                                `content[delivery_point_block__video_url]`,
                        }}
                        defaultValue={{
                            file: page.contentObject
                                .delivery_point_block__video_file,
                            url: page.contentObject
                                .delivery_point_block__video_url,
                        }}
                    />
                </div>

                <div className="mb-5">
                    <FileInput
                        label="Файл в блоке"
                        name="content[delivery_point_block__file][file_id]"
                        defaultValue={
                            page.contentObject.delivery_point_block__file
                        }
                        accept=".pdf, .docx, .xlsx"
                    />
                </div>
            </div>
        </div>
    );
}

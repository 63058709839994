import React from "react";

import type { BlockProps } from "@admin/components/Content/ContentBlocks";
import type { HeadingWithTextBlockValueModel } from "@admin/domain/content/models/ContentBlockValueModel";
import MultiLanguages from "@admin/components/MultiLanguage";
import HeadingInput from "@admin/components/Content/Blocks/HeadingInput";

type Props = BlockProps<HeadingWithTextBlockValueModel>;

const defaultValue: HeadingWithTextBlockValueModel = {
    value: `h2`,
    title: {},
    text: {},
};

export default function HeadingWithTextBlock({
    index,
    value = structuredClone(defaultValue),
}: Props) {
    return (
        <>
            <HeadingInput
                name={`blocks[${index}][value]`}
                value={value.value}
                title={value.title}
            />

            <MultiLanguages
                languageElement={(language) => (
                    <div className="mb-5">
                        <label className="form-label">Текст</label>
                        <textarea
                            className="form-control"
                            name={`blocks[${index}][value][text][${language}]`}
                            defaultValue={value.text[language] || ``}
                        />
                    </div>
                )}
            />
        </>
    );
}

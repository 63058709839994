export enum OrderStatus {
    New = `new`,
    Registered = `registered`,
    BeingEdited = `being_edited`,
    BeingAssembled = `being_assembled`,
    BeingDelivered = `being_delivered`,
    Delivered = `delivered`,
    Issued = `issued`,
    Returned = `returned`,
    Cancelled = `cancelled`,
}

export const readableOrderStatus = (status: OrderStatus) => {
    const map = new Map([
        [OrderStatus.New, `Ожидает оплаты`],
        [OrderStatus.Registered, `В обработке`],
        [OrderStatus.BeingEdited, `Редактируется`],
        [OrderStatus.BeingAssembled, `На сборке`],
        [OrderStatus.BeingDelivered, `Доставляется`],
        [OrderStatus.Delivered, `Доставлен`],
        [OrderStatus.Issued, `Выдан`],
        [OrderStatus.Returned, `Возвращён отправителю`],
        [OrderStatus.Cancelled, `Отменен`],
    ]);

    return map.get(status);
};

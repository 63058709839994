import React from "react";
import { useOrderContext } from "@admin/pages/OrderEditor/OrderContext";
import Input from "@admin/components/Input";
import { useLoaderContext } from "@admin/components/LoaderContext";
import { storeLog as storeLogRequest } from "@admin/domain/orders/api";
import { useOrderHooks } from "@admin/pages/OrderEditor/hooks";

export default function Logs() {
    const { order } = useOrderContext();
    const [log, setLog] = React.useState<string>(``);
    const { request, isLoading } = useLoaderContext();
    const { actualizeOrder } = useOrderHooks();

    const addLog = async (): Promise<void> => {
        request(async (): Promise<void> => {
            await storeLogRequest(order.id, log);
            await actualizeOrder();
        });
    };

    return (
        <div className="rounded border p-10 my-5">
            <h4 className="mb-5">Примечания</h4>

            <div className="py-5">
                <table className="table table-row-bordered gy-7">
                    <thead>
                        <tr className="fw-bolder fs-6 text-gray-800">
                            <th style={{ width: `200px` }}>Дата</th>
                            <th>Событие</th>
                        </tr>
                    </thead>
                    <tbody>
                        {order.logs.map(
                            ({ created_at, message }, index: number) => (
                                <tr key={`${index}-${created_at}`}>
                                    <td>{created_at}</td>
                                    <td>{message}</td>
                                </tr>
                            ),
                        )}
                    </tbody>
                </table>
            </div>

            <div className="d-flex align-items-end flex-column">
                <Input
                    label="Примечание"
                    className={{ block: `col-12 mb-5` }}
                    value={log}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>): void =>
                        setLog(e.target.value)
                    }
                    disabled={isLoading}
                />
                <div className="col-4 mb-5 d-flex justify-content-end">
                    <button
                        className="btn btn-info"
                        disabled={isLoading}
                        onClick={addLog}
                    >
                        Добавить примечание
                    </button>
                </div>
            </div>
        </div>
    );
}

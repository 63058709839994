import React, { useState } from "react";

import { type UniquePageProps } from "../index";
import {
    LiveBroadcastModel,
    LiveBroadcastsPageModel,
} from "@admin/domain/uniquePages/models/LiveBroadcastsPageModel";
import Input from "@admin/components/Input";
import MultiLanguages from "@admin/components/MultiLanguage";
import TextArea from "@admin/components/TextArea";
import BroadcastItem from "@admin/pages/UniquePageEditor/LiveBroadcasts/BroadcastItem";

export default function LiveBroadcastsPage({
    page,
}: UniquePageProps<LiveBroadcastsPageModel>) {
    const [broadcasts, setBroadcasts] = useState<LiveBroadcastModel[]>(
        page.contentObject.broadcasts || [],
    );
    const subTitle = page.contentObject.sub_title || {};
    const description = page.contentObject.description || {};

    const addBroadcast = (): void =>
        setBroadcasts([
            ...broadcasts,
            {
                starts_at: null,
                is_finished: false,
                title: {},
                description: {},
                speakers: [{}],
                documents: [],
                video_link: null,
                video: null,
            },
        ]);

    const deleteBroadcast = (broadcastIndex: number): void => {
        setBroadcasts(
            broadcasts.filter((_, index) => broadcastIndex !== index),
        );
    };

    return (
        <div>
            <h2>Контент страницы "Живые эфиры"</h2>

            <MultiLanguages
                languageElement={(language) => (
                    <>
                        <Input
                            label="Подзаголовок"
                            name={`content[sub_title][${language}]`}
                            defaultValue={subTitle[language] || ``}
                        />

                        <TextArea
                            label="Описание"
                            name={`content[description][${language}]`}
                            defaultValue={description[language] || ``}
                            required
                        />
                    </>
                )}
            />

            <h4 className="mb-5">Список эфиров</h4>

            {broadcasts.map(
                (broadcast: LiveBroadcastModel, broadcastIndex: number) => {
                    return (
                        <BroadcastItem
                            broadcast={broadcast}
                            broadcastIndex={broadcastIndex}
                            deleteBroadcast={deleteBroadcast}
                            key={broadcastIndex}
                        />
                    );
                },
            )}

            <button
                type="button"
                className="btn btn-primary mb-5"
                onClick={addBroadcast}
            >
                Добавить эфир
            </button>
        </div>
    );
}

import React from "react";
import { Link, Paginator } from "@admin/domain/api/Paginator";
import classNames from "classnames";
import PerPage from "@admin/components/Pagination/PerPage";

type Props = {
    paginator: Paginator;
};

export default function Pagination({ paginator }: Props) {
    return (
        <nav>
            <ul className="pagination">
                {paginator.links.map((link: Link, index: number) => {
                    return (
                        <li
                            className={classNames(`page-item`, {
                                active: link.active,
                            })}
                            key={index}
                        >
                            <a className="page-link" href={link.url || `#`}>
                                {link.label}
                            </a>
                        </li>
                    );
                })}

                <PerPage perPage={paginator.per_page} />
            </ul>
        </nav>
    );
}

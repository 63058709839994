import React from "react";
import debounce from "lodash-es/debounce";
import { SelectOption } from "@admin/utils/types";
import { searchLocalities as searchLocalitiesRequest } from "@admin/domain/localities/api";
import { LocalityModel } from "@admin/domain/localities/models/LocalityModel";
import { toDictionary } from "@admin/utils/converters";
import AsyncSelect from "react-select/async";

type Props = {
    countryId: number;
    label: string;
    defaultLocalityId: number | null;
    defaultLocalityName: string | null;
    onChange: (locality: LocalityModel) => void;
};

export default function LocalitiesSearch({
    countryId,
    label,
    defaultLocalityName,
    defaultLocalityId,
    onChange,
}: Props) {
    const [localities, setLocalities] = React.useState<
        Map<number, LocalityModel>
    >(new Map());

    const searchLocality = debounce(
        (
            query: string,
            setOptionsCallback: (options: SelectOption[]) => void,
        ): void => {
            searchLocalitiesRequest(query, countryId)
                .then((localities: LocalityModel[]): void => {
                    setLocalities(toDictionary(localities));

                    setOptionsCallback(
                        localities.map(
                            (locality: LocalityModel): SelectOption => ({
                                value: locality.id,
                                label: `${locality.full_name_with_path}`,
                            }),
                        ),
                    );
                })
                .catch((): void => {
                    setLocalities(new Map());
                    setOptionsCallback([]);
                });

            return;
        },
        500,
    );

    return (
        <div className="col">
            <label className="form-label">{label}</label>
            <AsyncSelect
                placeholder="Поиск населенного пункта"
                isClearable
                required
                loadOptions={searchLocality}
                defaultValue={
                    defaultLocalityId
                        ? {
                              value: defaultLocalityId,
                              label: defaultLocalityName,
                          }
                        : undefined
                }
                onChange={(option): void =>
                    onChange(
                        localities.get(option?.value || 0) as LocalityModel,
                    )
                }
            />
        </div>
    );
}

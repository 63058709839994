import React from "react";

import type { FileModel } from "@admin/domain/files/model/FileModel";
import type { LanguageKeyObject } from "@admin/domain/languages/Languages";
import Input from "@admin/components/Input";
import TextArea from "@admin/components/TextArea";
import MultiLanguages from "@admin/components/MultiLanguage";
import ImageInput from "@admin/components/ImageInput";

type Props = {
    title: string;
    name: string;
    inputs: {
        title: LanguageKeyObject;
        description: LanguageKeyObject;
        icon: FileModel;
        url: string;
    };
};

export default function DeliveryAndPaymentBlockItem(props: Props) {
    return (
        <div className="col card border m-2 p-8">
            <h4 className="mb-5">{props.title}</h4>

            <ImageInput
                label=""
                name={`content[${props.name}__icon_id]`}
                defaultValue={props.inputs.icon}
                width="80px"
                height="80px"
            />

            <Input
                label="Ссылка"
                defaultValue={props.inputs.url}
                name={`content[${props.name}__url]`}
            />

            <MultiLanguages
                languageElement={(language) => (
                    <>
                        <Input
                            label="Заголовок"
                            defaultValue={props.inputs.title[language]}
                            name={`content[${props.name}__title][${language}]`}
                        />

                        <TextArea
                            label="Описание под заголовком"
                            defaultValue={props.inputs.description[language]}
                            name={`content[${props.name}__description][${language}]`}
                        />
                    </>
                )}
            />
        </div>
    );
}

import React from "react";
import { type EditOrderData } from "@admin/domain/orders/dto/EditOrderData";
import ProductsList from "@admin/pages/OrderEditor/Composition/SimpleOrder/ProductsList";
import Totals from "@admin/pages/OrderEditor/Composition/SimpleOrder/Totals";
import EditorButtons from "@admin/pages/OrderEditor/Composition/SimpleOrder/EditorButtons";

type Props = {
    order: EditOrderData;
};

export default function SimpleOrder({ order }: Props) {
    return (
        <>
            <ProductsList order={order} />
            <Totals order={order} />
            <EditorButtons order={order} />
        </>
    );
}

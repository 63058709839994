import React from "react";
import classNames from "classnames";
import isPlainObject from "lodash-es/isPlainObject";

import { Override } from "@admin/utils/overrideType";
import { useIsInvalid } from "../FormErrorsContext";

export type Option = {
    value: string | number;
    label: string | number;
};

type SelectInputProps = {
    label: string;
    options:
        | ReadonlyArray<Option>
        | Option[]
        | { [key: Option[`value`]]: Option[`label`] };
    className?: { block?: string };
    prependEmptyOption?: boolean;
} & Override<
    React.DetailedHTMLProps<
        React.SelectHTMLAttributes<HTMLSelectElement>,
        HTMLSelectElement
    >,
    {
        className?: { block?: string };
    }
>;

const Select: React.FC<SelectInputProps> = ({
    label,
    options,
    className,
    prependEmptyOption = true,
    ...props
}) => {
    const isInvalid = useIsInvalid(props.name);

    if (isPlainObject(options)) {
        // eslint-disable-next-line no-console
        console.warn(
            `Необходимо поменять формат Select.options для "${props.name}" на массив`,
        );

        options = Object.entries(options).map(([value, label]) => ({
            value,
            label,
        }));
    }

    return (
        <div className={className?.block || `mb-5`}>
            <label className="form-label">{label}</label>
            <select
                {...props}
                className={classNames(`form-select`, {
                    "is-invalid": isInvalid,
                })}
            >
                {prependEmptyOption && <option></option>}
                {(options as Option[]).map(({ value, label }) => (
                    <option key={value} value={value}>
                        {label}
                    </option>
                ))}
            </select>
        </div>
    );
};

export default Select;

import React, { useEffect } from "react";
import { type UserModel } from "@admin/domain/users/models/UserModel";
import { getConsolidatedOrders as getConsolidateOrdersRequest } from "@admin/domain/orders/api";
import { useLoaderContext } from "@admin/components/LoaderContext";
import { ConsolidatedOrderData } from "@admin/domain/orders/dto/ConsolidatedOrderData";
import Select from "@admin/components/Select";
import { toDictionary } from "@admin/utils/converters";

type Props = {
    creator: UserModel;
    setSelectedConsolidatedOrder: (order: ConsolidatedOrderData | null) => void;
};

export default function ConsolidatedOrders({
    creator,
    setSelectedConsolidatedOrder,
}: Props) {
    const { request, isLoading } = useLoaderContext();
    const [consolidatedOrders, setConsolidatedOrders] = React.useState<
        Map<number, ConsolidatedOrderData>
    >(new Map());

    const getConsolidatedOrders = async (): Promise<void> => {
        request(async (): Promise<void> => {
            const consolidatedOrders = await getConsolidateOrdersRequest(
                creator.id,
            );

            setSelectedConsolidatedOrder(consolidatedOrders[0] || null);
            setConsolidatedOrders(toDictionary(consolidatedOrders));
        });
    };

    useEffect((): void => {
        // noinspection JSIgnoredPromiseFromCall
        getConsolidatedOrders();

        return;
    }, [creator]);

    if (isLoading) {
        return <p>Загружается...</p>;
    }

    const options = Array.from(consolidatedOrders, ([, order]) => ({
        label: `Консолидированный заказ №${order.id}, сумма ${order.total / 100} ${order.currency_symbol}`,
        value: order.id,
    }));

    return (
        <Select
            label="Выбор консолидированного заказа"
            options={[
                ...options,
                {
                    label: `Новый консолидированный заказ`,
                    value: 0,
                },
            ]}
            prependEmptyOption={false}
            onChange={(e: React.ChangeEvent<HTMLSelectElement>): void =>
                setSelectedConsolidatedOrder(
                    consolidatedOrders.get(+e.target.value) || null,
                )
            }
        />
    );
}

import React, { useState } from "react";
import isObject from "lodash/isObject";

import type { FileModel } from "@admin/domain/files/model/FileModel";
import Link from "./Link";
import File from "./File";

type Props = {
    name:
        | string
        | {
              file: string | (() => string);
              url?: string | (() => string);
          };
    defaultValue: {
        file: FileModel | null | undefined;
        url?: string | null | undefined;
    };
    allowLink?: boolean;
};

export default function VideoInput({
    name,
    defaultValue: { file: defaultFile, url: defaultUrl },
    allowLink = true,
}: Props) {
    const [file, setFile] = useState<FileModel | null>(defaultFile || null);
    const [url, setUrl] = useState<string | null>(defaultUrl || null);

    let fileName;
    let urlName;

    if (isObject(name)) {
        fileName = name.file;
        urlName = name.url;
    } else {
        fileName = name;
        urlName = name;
    }

    return (
        <>
            {allowLink && (
                <Link
                    name={urlName || ``}
                    value={url}
                    onChange={setUrl}
                    disabled={!!file}
                />
            )}

            <File
                name={fileName || ``}
                value={file}
                onChange={setFile}
                disabled={!!url}
            />
        </>
    );
}

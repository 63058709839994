import React from "react";

import type { FileModel } from "@admin/domain/files/model/FileModel";
import type { BlockProps } from "@admin/components/Content/ContentBlocks";
import type { FilesBlockValueModel } from "@admin/domain/content/models/ContentBlockValueModel";
import FilesList from "@admin/components/FilesList";

const defaultValue: FilesBlockValueModel = {
    file_list: [{ file: null }],
};

type Props = BlockProps<FilesBlockValueModel>;

export default function FilesBlock({
    index,
    value = structuredClone(defaultValue),
}: Props) {
    return (
        <FilesList
            fileInputFunction={(fileIndex: number) =>
                `blocks[${index}][value][file_list][${fileIndex}][file_id]`
            }
            files={value.file_list.map(({ file }): FileModel | null => file)}
        />
    );
}

import Input from "@admin/components/Input";
import { LanguageKeyObject } from "@admin/domain/languages/Languages";
import React from "react";
import MultiLanguages from "@admin/components/MultiLanguage";

type Props = {
    index: LanguageKeyObject;
    title: string;
    url: string;
    remove: () => void;
};

export default function AdditionalMaterialsPageBlockItem(props: Props) {
    return (
        <div className="col card border m-2 p-8">
            <div>
                <MultiLanguages
                    languageElement={(language) => (
                        <>
                            <Input
                                label="Заголовок"
                                name={`content[pages][${props.index}][title][${language}]`}
                                defaultValue={props?.title[language] || ``}
                            />
                        </>
                    )}
                />
                <Input
                    label="Ссылка"
                    defaultValue={props.url}
                    name={`content[pages][${props.index}][url]`}
                />
            </div>
            <div className="mt-8">
                <a className="card-link text-danger" onClick={props.remove}>
                    Удалить страницу
                </a>
            </div>
        </div>
    );
}

import React from "react";
import BaseModal from "@admin/components/Modal";
import { useLoaderContext } from "@admin/components/LoaderContext";
import { HTTP_FORBIDDEN } from "@admin/utils/httpStatuses";

export default function ErrorsModal() {
    const { messages, clearMessages, errorCode } = useLoaderContext();

    const closeModal = (): void => {
        if (errorCode === HTTP_FORBIDDEN) {
            location.reload();

            return;
        }

        clearMessages();
    };

    if (messages.length === 0) {
        return null;
    }

    return (
        <BaseModal
            closeModal={closeModal}
            title="В процессе редактирования заказа произошли ошибки"
            body={messages.map((error: string, index: number) => (
                <p key={`error_${index}`}>{error}</p>
            ))}
            footer={
                <button className="btn btn-secondary" onClick={closeModal}>
                    Закрыть
                </button>
            }
        />
    );
}

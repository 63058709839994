import React, { useState } from "react";
import Sortable from "@admin/components/Sortable";
import { FooterBlockModel } from "@admin/domain/footer/Models/FooterBlockModel";
import FooterBlocksModal from "@admin/pages/FooterEditor/FooterBlocksModal";
import FooterBlockItem from "@admin/pages/FooterEditor/FooterBlockItem";

export type FooterBlocksProps = {
    blocks: FooterBlockModel[] | null;
};

export default function FooterBlocksEditor(props: FooterBlocksProps) {
    const [blocks, setBlocks] = useState<FooterBlockModel[]>(
        props.blocks || [],
    );
    const [editableBlock, setEditableBlock] = useState<FooterBlockModel | null>(
        null,
    );
    const [editableBlockIndex, setEditableBlockIndex] = useState<number | null>(
        null,
    );

    const addBlock = (block: FooterBlockModel): void =>
        setBlocks([...blocks, block]);

    const updateBlock = (newBlock: FooterBlockModel): void => {
        setBlocks(
            blocks.map((oldBlock: FooterBlockModel, oldBlockIndex: number) => {
                return oldBlockIndex === editableBlockIndex
                    ? newBlock
                    : oldBlock;
            }),
        );

        setEditableBlock(null);
    };

    return (
        <>
            <Sortable list={blocks} setList={setBlocks}>
                {blocks.map((block: FooterBlockModel, index: number) => (
                    <FooterBlockItem
                        block={block}
                        index={index}
                        editBlock={(): void => {
                            setEditableBlock(block);
                            setEditableBlockIndex(index);
                        }}
                        deleteBlock={(): void =>
                            setBlocks(
                                blocks.filter(
                                    (_, blockIndex): boolean =>
                                        blockIndex !== index,
                                ),
                            )
                        }
                        key={index}
                    />
                ))}
            </Sortable>
            {blocks.length < 5 && (
                <div className="d-flex justify-content-end mb-5">
                    <button
                        className="btn btn-primary"
                        type="button"
                        onClick={(): void => {
                            setEditableBlock({
                                id: 0,
                                title: ``,
                                value: [],
                            });
                        }}
                    >
                        Добавить блок
                    </button>
                </div>
            )}

            {editableBlock && (
                <FooterBlocksModal
                    closeModal={() => setEditableBlock(null)}
                    onSubmit={editableBlock.id ? updateBlock : addBlock}
                    editableBlock={editableBlock}
                />
            )}
        </>
    );
}

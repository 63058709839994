import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import { Alert } from "react-bootstrap";
import { v4 as uuid } from "uuid";

import type { BuyerChoiceBannerModel } from "@admin/domain/uniquePages/models/MainPageModel";
import type { FileModel } from "@admin/domain/files/model/FileModel";
import { Language } from "@admin/domain/languages/Languages";
import Input from "@admin/components/Input";
import ImageInput from "@admin/components/ImageInput";

type Props = {
    showModal: boolean;
    closeModal: () => void;
    onSubmit: (data: BuyerChoiceBannerModel) => void;
    editableBanner?: BuyerChoiceBannerModel;
};

export default function MainPageBuyerChoiceModal(props: Props) {
    const [banner, setBanner] = useState<BuyerChoiceBannerModel>(
        props.editableBanner || {
            id: uuid(),
            product: {
                id: ``,
                text: {
                    language: Language.Russian,
                    title: ``,
                    description: ``,
                    excerpt: ``,
                    usage: ``,
                    keywords: ``,
                },
            },
            file: null,
            text: ``,
            reviewer_name: ``,
            full_name: ``,
            reviewer_file: null,
        },
    );

    const [errors, setErrors] = useState<string[]>([]);
    const submit = () => {
        const newErrors: string[] = [];
        if (!banner.file) newErrors.push(`Изображение обязательно`);
        if (newErrors.length > 0) {
            setErrors(newErrors);
            return;
        }

        props.onSubmit({ ...banner, file: banner.file as FileModel });
        props.closeModal();
    };

    return (
        <Modal centered show={props.showModal} onHide={props.closeModal}>
            <Modal.Header>
                <Modal.Title>
                    {props.editableBanner ? `Обновление` : `Добавление`} банера
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {errors.length > 0 && (
                    <Alert variant="danger">
                        {errors.map((err, index) => (
                            <div key={index}>{err}</div>
                        ))}
                    </Alert>
                )}
                <div className="align-items-center justify-content-center">
                    <ImageInput
                        label="Изображение"
                        name=""
                        defaultValue={banner.file}
                        onChange={(file) => setBanner({ ...banner, file })}
                        width="400px"
                        height="400px"
                    />
                    <Input
                        label="Текст отзыва"
                        onChange={(event) =>
                            setBanner((prev) => ({
                                ...prev,
                                text: event.target.value,
                            }))
                        }
                        value={banner.text}
                    />
                    <Input
                        label="Имя создателя отзыва"
                        onChange={(event) =>
                            setBanner((prev) => ({
                                ...prev,
                                reviewer_name: event.target.value,
                            }))
                        }
                        value={banner.reviewer_name}
                    />

                    <ImageInput
                        label="Аватар"
                        name=""
                        defaultValue={banner.reviewer_file}
                        onChange={(file) =>
                            setBanner({
                                ...banner,
                                reviewer_file: file,
                            })
                        }
                        width="200px"
                        height="200px"
                    />
                </div>
            </Modal.Body>
            <Modal.Footer>
                <button
                    className="btn btn-secondary"
                    onClick={props.closeModal}
                >
                    Закрыть
                </button>
                <button className="btn btn-primary" onClick={submit}>
                    {props.editableBanner ? `Обновить` : `Добавить`}
                </button>
            </Modal.Footer>
        </Modal>
    );
}

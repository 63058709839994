import React, { useState } from "react";
import { v4 as uuid } from "uuid";

import { ArticleContentBlockModel } from "@admin/domain/articles/models/ArticleContentBlockModel";
import { NotificationBannersModel } from "@admin/domain/articles/models/ArticleBlockValueModel";
import { ArticleBlockType } from "@admin/domain/articles/enums/ArticleBlockType";
import MultiLanguages from "@admin/components/MultiLanguage";

type Banner = Partial<NotificationBannersModel[`notification_banners`][0]>;

type Props = {
    block: ArticleContentBlockModel & {
        type: ArticleBlockType.Notifications;
    };
    index: number;
};

export default function NotificationsBlock({
    block: { id, value },
    index,
}: Props) {
    const initBanners = (value?.notification_banners || []).map((value) => ({
        ...value,
        id: uuid(),
    }));

    const [banners, setBanners] = useState<Banner[]>(initBanners);

    return (
        <>
            <input
                type="hidden"
                name={`blocks[${index}][value][id]`}
                value={id}
            />

            <MultiLanguages
                languageElement={(language) => (
                    <div className="mb-5">
                        <label className="form-label">Заголовок</label>
                        <input
                            className="form-control"
                            name={`blocks[${index}][value][title][${language}]`}
                            defaultValue={value?.title?.[language] || ``}
                        />
                    </div>
                )}
            />

            <div className="row row-cols-auto">
                {banners.map((banner, itemIndex) => (
                    <div key={banner.id} className="col mb-7">
                        <MultiLanguages
                            languageElement={(language) => (
                                <>
                                    <div className="mb-7">
                                        <label className="form-label">
                                            Заголовок
                                        </label>
                                        <textarea
                                            className="form-control"
                                            rows={5}
                                            cols={50}
                                            name={`blocks[${index}][value][notification_banners][${itemIndex}][title][${language}]`}
                                            defaultValue={
                                                banner?.title?.[language] || ``
                                            }
                                        />
                                    </div>

                                    <div className="mb-7">
                                        <label className="form-label">
                                            Текст
                                        </label>
                                        <textarea
                                            className="form-control"
                                            rows={5}
                                            cols={50}
                                            name={`blocks[${index}][value][notification_banners][${itemIndex}][text][${language}]`}
                                            defaultValue={
                                                banner?.text?.[language] || ``
                                            }
                                        />
                                    </div>
                                </>
                            )}
                        />

                        <div>
                            <a
                                className="card-link text-danger"
                                onClick={() => {
                                    setBanners((prev) =>
                                        prev.filter(
                                            (value) => value.id !== banner.id,
                                        ),
                                    );
                                }}
                            >
                                Удалить
                            </a>
                        </div>
                    </div>
                ))}

                <div className="mb-5 d-flex">
                    <div className="me-5">
                        <button
                            type="button"
                            className="btn btn-success"
                            onClick={() =>
                                setBanners((prevState) => [
                                    ...prevState,
                                    {
                                        id: uuid(),
                                        title: undefined,
                                        text: undefined,
                                    },
                                ])
                            }
                        >
                            Добавить
                        </button>
                    </div>
                </div>
            </div>
        </>
    );
}

import React from "react";
import { useOrderContext } from "@admin/pages/OrderEditor/OrderContext";
import Input from "@admin/components/Input";
import ReactJson from "react-json-view";
import { Collapse } from "react-bootstrap";
import { useLoaderContext } from "@admin/components/LoaderContext";
import { useOrderHooks } from "@admin/pages/OrderEditor/hooks";
import { storeOrderReceipt } from "@admin/domain/orders/api";
import ResponseError from "@admin/domain/api/ResponseError";
import formatMoney from "@admin/domain/orders/formatMoney";

export default function Fiscalization() {
    const { order } = useOrderContext();
    const [receiptDataIsCollapsed, setReceiptDataIsCollapsed] =
        React.useState<boolean>(false);

    const { isLoading, request } = useLoaderContext();
    const { actualizeOrder } = useOrderHooks();
    const storeReceipt = (): void => {
        request(async (): Promise<void> => {
            await storeOrderReceipt(order.id);
            const actualOrder = await actualizeOrder();

            if (!actualOrder.receipt_id) {
                throw new ResponseError(`Не удалось сохранить чек`);
            }
        });
    };

    return (
        <div className="rounded border p-10 my-5">
            <h4 className="mb-5">Фискализация</h4>
            {order.is_child && order.consolidated_order_receipt_id && (
                <p>
                    Внимание! Существует чек по оплате для группового заказа
                    {` `}
                    <a
                        href={`/admin/orders/${order.consolidated_order_id}`}
                        target="_blank"
                    >
                        {order.consolidated_order_id}
                    </a>
                    {` `}
                    на сумму{` `}
                    {formatMoney(order, `consolidated_order_total_amount`)}
                </p>
            )}
            {order.receipt_id && (
                <p>Сумма по чеку {formatMoney(order, `total_amount`)}</p>
            )}
            <Input
                label="UUID чека"
                defaultValue={order.receipt_id}
                disabled
                className={{ block: `col-4 mb-5` }}
            />
            <Input
                label="Дата создания чека"
                defaultValue={order.receipt_created_at}
                disabled
                className={{ block: `col-4 mb-5` }}
            />
            {order.receipt_data && (
                <div className="border rounded p-5 mb-3">
                    <div
                        className="cursor-pointer"
                        onClick={(): void =>
                            setReceiptDataIsCollapsed(!receiptDataIsCollapsed)
                        }
                    >
                        {receiptDataIsCollapsed ? `Скрыть чек` : `Показать чек`}
                    </div>
                    <Collapse in={receiptDataIsCollapsed}>
                        <div className="mb-5">
                            <ReactJson
                                src={order.receipt_data}
                                displayDataTypes={false}
                                displayObjectSize={false}
                                collapseStringsAfterLength={false}
                                enableClipboard={false}
                                name={false}
                            />
                        </div>
                    </Collapse>
                </div>
            )}
            {order.paid_at && !order.receipt_id && (
                <div className="d-flex justify-content-end">
                    <div className="d-flex flex-column">
                        <p>
                            Чек будет создан на сумму{` `}
                            {formatMoney(order, `total_amount`)}
                        </p>
                        <button
                            className="btn btn-info"
                            disabled={isLoading}
                            onClick={storeReceipt}
                        >
                            Создать чек
                        </button>
                    </div>
                </div>
            )}
        </div>
    );
}

import React from "react";
import { type EditOrderData } from "@admin/domain/orders/dto/EditOrderData";
import Accounting from "@admin/pages/OrderEditor/AccountingAndAnaliticalDb/Accounting";
import AnalyticalDb from "@admin/pages/OrderEditor/AccountingAndAnaliticalDb/AnalyticalDb";

type Props = {
    order: EditOrderData;
};

export default function AccountingAndAnaliticalDb({ order }: Props) {
    return (
        <div className="rounded border p-10 my-5">
            <Accounting order={order} />
            <AnalyticalDb order={order} />
        </div>
    );
}

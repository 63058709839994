import React from "react";
import { Banner } from "./index";
import CheckBox from "@admin/components/CheckBox";
import { FileModel } from "@admin/domain/files/model/FileModel";
import Input from "@admin/components/Input";
import { Language } from "@admin/domain/languages/Languages";
import MultiLanguages from "@admin/components/MultiLanguage";
import ImageInput from "@admin/components/ImageInput";

type Props = {
    setBanner: React.Dispatch<React.SetStateAction<Banner>>;
    banner: Banner;
};

export default function MobileAppFields({ setBanner, banner }: Props) {
    return (
        <>
            <CheckBox
                label="отображать в мобильном приложении"
                checked={banner.with_mobile_app_file}
                onChange={(e) => {
                    setBanner({
                        ...banner,
                        with_mobile_app_file: e.target.checked,
                    });
                }}
            />

            {banner.with_mobile_app_file && (
                <>
                    <div className="d-flex align-items-center justify-content-center">
                        <ImageInput
                            label="Изображение"
                            name=""
                            defaultValue={banner.mobile_app_file}
                            onChange={(file: FileModel | null): void =>
                                setBanner({
                                    ...banner,
                                    mobile_app_file: file,
                                })
                            }
                        />
                    </div>

                    <MultiLanguages
                        languageElement={(language: Language) => (
                            <>
                                <Input
                                    label="Заголовок для МП"
                                    onChange={(event) =>
                                        setBanner((prev) => {
                                            const mobile_app_title =
                                                prev.mobile_app_title || {};
                                            mobile_app_title[language] =
                                                event.target.value;
                                            return {
                                                ...prev,
                                                mobile_app_title,
                                            };
                                        })
                                    }
                                    value={
                                        banner.mobile_app_title?.[language] ||
                                        ``
                                    }
                                />

                                <Input
                                    label="Текст под заголовком для МП"
                                    onChange={(event) =>
                                        setBanner((prev) => {
                                            const mobile_app_description =
                                                prev.mobile_app_description ||
                                                {};
                                            mobile_app_description[language] =
                                                event.target.value;
                                            return {
                                                ...prev,
                                                mobile_app_description,
                                            };
                                        })
                                    }
                                    value={
                                        banner.mobile_app_description?.[
                                            language
                                        ]
                                    }
                                />
                            </>
                        )}
                    />

                    <Input
                        label="Ссылка для МП"
                        onChange={(event) =>
                            setBanner((prev) => ({
                                ...prev,
                                mobile_app_url: event.target.value,
                            }))
                        }
                        value={banner.mobile_app_url || ``}
                    />
                </>
            )}
        </>
    );
}

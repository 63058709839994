import { useOrderContext } from "@admin/pages/OrderEditor/OrderContext";
import { getOrder } from "@admin/domain/orders/api";
import { EditOrderData } from "@admin/domain/orders/dto/EditOrderData";

export const useOrderHooks = () => {
    const { order, setOrder } = useOrderContext();

    const actualizeOrder = async (): Promise<EditOrderData> => {
        const { order: actualOrder } = await getOrder(order.id);
        setOrder(actualOrder);

        return actualOrder;
    };

    return {
        actualizeOrder,
    };
};

export const addQueryToUrl = (
    query: Record<string, string | number | boolean | null>,
): string => {
    const url = new URL(window.location.href);

    Object.entries(query).forEach(([name, value]): void => {
        if (value === true || value === false) {
            value = value ? 1 : 0;
        }

        if (value === null) {
            url.searchParams.delete(name);
        } else {
            url.searchParams.set(name, value.toString());
        }
    });

    return url.toString();
};

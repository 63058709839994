import { post, request } from "../api";
import { DeliveryPointModel } from "./models/DeliveryPointModel";

const createDeliveryPoint = async (
    body: FormData,
): Promise<DeliveryPointModel> => {
    return await post({
        url: `/admin/delivery_points`,
        body,
    });
};

const updateDeliveryPoint = async (
    id: DeliveryPointModel[`id`],
    body: FormData,
): Promise<DeliveryPointModel> => {
    return await post({
        url: `/admin/delivery_points/${id}`,
        body,
    });
};

export const saveDeliveryPoint = async (
    deliveryPoint: DeliveryPointModel | null,
    body: FormData,
): Promise<DeliveryPointModel> => {
    return deliveryPoint
        ? updateDeliveryPoint(deliveryPoint.id, body)
        : createDeliveryPoint(body);
};

export const searchDeliveryPoints = async (
    localityId: number,
    deliveryMethodId: number,
): Promise<DeliveryPointModel[]> => {
    return await request({
        url: `/admin/delivery_points/search`,
        query: {
            locality_id: localityId,
            delivery_method_id: deliveryMethodId,
        },
    });
};

import React from "react";

import { useOrderContext } from "../OrderContext";
import SimpleOrder from "./SimpleOrder";
import ConsolidateOrder from "./ConsolidatedOrder";

export default function Composition() {
    const { order } = useOrderContext();

    return (
        <>
            <div className="rounded border p-10 my-5">
                <h4 className="mb-5">Состав заказа</h4>
                {order.is_parent ? (
                    <ConsolidateOrder />
                ) : (
                    <SimpleOrder order={order} />
                )}
            </div>
        </>
    );
}

import { post, request } from "../api";
import { type CustomPageModel } from "@admin/domain/customPages/models/CustomPageModel";

async function createCustomPage(body: FormData): Promise<CustomPageModel> {
    return await post({
        url: `/admin/custom_pages`,
        body: body,
    });
}

async function updateCustomPage(
    id: CustomPageModel[`id`],
    body: FormData,
): Promise<CustomPageModel> {
    return await post({
        url: `/admin/custom_pages/${id}`,
        body: body,
    });
}

export async function saveCustomPage(
    page: CustomPageModel | null,
    body: FormData,
): Promise<CustomPageModel> {
    return page ? updateCustomPage(page.id, body) : createCustomPage(body);
}

export type FoundCustomPagesPayload = {
    customPages: ReadonlyArray<FoundCustomPage>;
};

export type FoundCustomPage = {
    id: CustomPageModel[`id`];
    title: CustomPageModel[`texts`][0][`title`];
};

export const searchCustomPages = async (
    query: string,
): Promise<FoundCustomPagesPayload> => {
    return await request({
        url: `/admin/custom_pages/search`,
        query: { query },
    });
};

import React from "react";
import Select from "@admin/components/Select";
import Input from "@admin/components/Input";
import { useDataContext } from "@admin/components/DataLoader";
import { LogData } from "@admin/domain/logs/dto/LogItem";
import { getFormattedDate } from "@admin/utils/dates";

export default function Filters() {
    const {
        data: { actions, query },
    } = useDataContext<LogData>();

    return (
        <form action="/admin/logs" className="mb-5">
            <h1>Фильтры</h1>
            <div className="input-group mb-5">
                <input
                    className="form-control me-2"
                    type="search"
                    name="search"
                    placeholder="Поиск"
                    defaultValue={query.search || undefined}
                />
            </div>
            <div className="mb-5 row">
                <Select
                    label="Тип события"
                    options={actions.map((action: string) => ({
                        label: action,
                        value: action,
                    }))}
                    className={{ block: `col` }}
                    defaultValue={query.action || undefined}
                    name="action"
                />

                <Input
                    type="datetime-local"
                    label="Дата создания от"
                    className={{ block: `col` }}
                    name="created_at_from"
                    defaultValue={
                        query.created_at_from
                            ? getFormattedDate(query.created_at_from)
                            : undefined
                    }
                />

                <Input
                    type="datetime-local"
                    label="Дата создания до"
                    className={{ block: `col` }}
                    name="created_at_to"
                    defaultValue={
                        query.created_at_to
                            ? getFormattedDate(query.created_at_to)
                            : undefined
                    }
                />
            </div>
            <div className="d-flex justify-content-end">
                <button
                    type="submit"
                    className="btn btn-primary me-2"
                    id="localities_search"
                >
                    Применить
                </button>
                <a className="btn btn-danger" href="/admin/logs">
                    Сбросить
                </a>
            </div>
        </form>
    );
}

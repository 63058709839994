import React from "react";

import { type Language } from "@admin/domain/languages/Languages";
import { saveRubric } from "@admin/domain/rubrics/api";
import Editor from "@admin/components/Editor";
import Input from "@admin/components/Input";
import Switch from "@admin/components/Switch";
import MultiLanguages from "@admin/components/MultiLanguage";

const LIST_URL = `/admin/rubrics` as const;

export default function RubricEditor() {
    const rubric = window.CMS.rubric || null;

    const execute = async (body: FormData) => {
        await saveRubric(rubric, body);
    };

    return (
        <Editor backUrl={LIST_URL} onSubmit={execute}>
            <MultiLanguages
                languageElement={(language: Language): React.JSX.Element => (
                    <Input
                        label="Название"
                        defaultValue={rubric?.texts[language]?.title || ``}
                        name={`texts[${language}][title]`}
                    />
                )}
            />

            <Input
                label="Порядок отображения"
                name="order"
                type="number"
                defaultValue={rubric?.order || rubric?.next_order}
            />

            <Switch
                label="Скрыть / Показать"
                name="is_public"
                defaultChecked={rubric?.is_public}
            />
        </Editor>
    );
}
